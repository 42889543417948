import React from 'react';
import styled from 'styled-components';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

const CKEditorContainer = styled.div`
  .ck.ck-button {
    font-size: 12px;
  }
  
  .ck.ck-toolbar {
    background-color: #fff;
    border-bottom: none;
  }
  
  .ck.ck-content {
    font-family: "Open Sans", sans-serif;
    height: ${({ richtextheight }) => richtextheight};
    
    ul, ol {
      margin-left: 20px!important;;
    }
  }
`;

const config = {
  fontSize: {
    options: [
      'tiny',
      'default',
      'big'
    ]
  },
  toolbar: [
    'heading', 'fontSize', '|', 'blockQuote', 'bold', 'italic', 'link', 'bulletedList',
    'numberedList', 'outdent', 'indent', '|', 'insertTable'
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
    ]
  },
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  table: {
    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
  },
};

const RichText = ({ className, height = '150px', ...props}) => (
  <CKEditorContainer richtextheight={height} className={className}>
    <CKEditor
      {...props}
      editor={ClassicEditor}
      config={config}
    />
  </CKEditorContainer>
);

export default RichText;
