import React from 'react';
import ABTestingExperiments from './ABTestingExperiments';
import { NotificationManager } from 'react-notifications';
import { apiGetOptimizeExperiments, apiSaveOptimizeExperiment } from './api';

const ABTestingExperimentsContainer = () => {
  const [experiments, setExperiments] = React.useState([]);
  const handleSaveDemoSpaceExperimentId = React.useCallback(async ({disabled, experimentId}) => {
    try {
      await apiSaveOptimizeExperiment({
        experimentId,
        disabled,
      });
      NotificationManager.success('Your changes have been saved');
    } catch (err) {
      NotificationManager.error('Something went wrong, please try again later');
    }
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetOptimizeExperiments();
        setExperiments(result);
      } catch (err) {
        NotificationManager.error('Something went wrong, please try again later');
      }
    };

    fetch();
  }, []);

  return (
    <ABTestingExperiments
      experiments={experiments}
      onSaveDemoSpaceExperimentId={handleSaveDemoSpaceExperimentId}
    />
  );
};

export default ABTestingExperimentsContainer;
