import React from 'react';
import * as yup from 'yup';
import { Formik, Field, FieldArray } from 'formik';
import FormDialog from '../../../../ui/FormDialog';
import FormGroup from '../../../../ui/FormGroup';
import Label from '../../../../ui/Label';
import { CustomErrorMessage } from '../../../../ui/formik/CustomErrorMessage';
import FormikCustomFieldValuesList from '../../../../shared/CustomFieldValuesList/FormikCustomFieldValuesList';
import { Row } from '../../../../ui/Flex';
import { FormikRadio } from '../../../../ui/FormikRadio';
import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import FormikCustomFieldInput from './FormikCustomFieldInput';
import FormikTextInput from '../../../../ui/FormikTextInput';


const customFieldSchema = yup.object({
  name: yup.string().required('the name is required'),
  type: yup.string().required('the type is required').oneOf(['text', 'list']),
  content: yup.string(),
});

const CustomFieldDialog = ({ customField, onSave, onCancel }) => {
  return (
    <Formik
      initialValues={{
        name: customField?.name || '',
        content: customField?.content || '',
        type: customField?.type ||'text',
        required: customField?.required || false,
        validationRule: customField?.validationRule || 'UNSPECIFIED',
        exampleValues: customField?.exampleValues || [],
      }}
      validateOnChange={false}
      validateOnBlur
      validationSchema={customFieldSchema}
      onSubmit={onSave}
    >
      {({ handleSubmit, values, form }) => (
        <FormDialog
          open
          title="Add Custom field"
          closeDialog={onCancel}
          buttons={[
            { type: 'ok', text: 'save', onClick: handleSubmit },
            { type: 'cancel', text: 'cancel' }
          ]}
        >
          <FormGroup>
            <Label htmlFor="name">
              Name
            </Label>
            <FormikTextInput
              type="text"
              name="name"
            />
            <CustomErrorMessage name="name" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="type">
              Type
            </Label>
            <Row>
              <FormikRadio name="type" value="text">Text</FormikRadio>
              <FormikRadio name="type" value="list">List</FormikRadio>
            </Row>
            <CustomErrorMessage name="type" />
          </FormGroup>
          {values.type === 'list' && (
            <FormGroup>
              <Label htmlFor="content">Values</Label>
              <FormikCustomFieldValuesList name="content" />
              <CustomErrorMessage name="content" />
            </FormGroup>
          )}
          <FormGroup>
            <Label htmlFor="required">Required</Label>
            <Row>
              <FormikRadio name="required" value={true}>Yes</FormikRadio>
              <FormikRadio name="required" value={false}>No</FormikRadio>
            </Row>
            <CustomErrorMessage name="required" />
          </FormGroup>
          {values.type === 'text' && <FormGroup>
            <Label htmlFor="validationRule">Validation rule</Label>
            <Row>
              <FormikRadio name="validationRule" value="EMAIL">Email</FormikRadio>
              <FormikRadio name="validationRule" value="URL">Url</FormikRadio>
              <FormikRadio name="validationRule" value="NUMBER">Number</FormikRadio>
              <FormikRadio name="validationRule" value="DATE">Date</FormikRadio>
              <FormikRadio name="validationRule" value="UNSPECIFIED">Non specified</FormikRadio>
            </Row>
            <CustomErrorMessage name="validationRule" />
          </FormGroup>}
          <FormGroup>
            <Label htmlFor="exampleValues">Values</Label>
            <Box>
              <FieldArray
                name="exampleValues"
                render={arrayHelpers => (
                  <Box>
                    {values.exampleValues && values.exampleValues.map((value, index) => (
                      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} key={index}>
                        <FormikCustomFieldInput
                          name={`exampleValues.${index}`}
                          content={values.content}
                          validationRule={values.validationRule}
                          type={values.type}
                        />
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <Delete />
                        </IconButton>
                        <IconButton onClick={() => arrayHelpers.insert(index, '')}>
                          <Add />
                        </IconButton>
                      </Box>
                    ))}
                    <IconButton
                      onClick={() => arrayHelpers.push('')}>
                      <Add />
                    </IconButton>
                  </Box>
                )}
              />

            </Box>
          </FormGroup>
        </FormDialog>
      )}
    </Formik>
  );
};

export default CustomFieldDialog;
