import apiClientRequest from '../../common/apiclient';

export const apiGetAnnouncements = () =>
  apiClientRequest('/announcements');

export const apiCreateAnnouncement = (payload) =>
  apiClientRequest('/announcements', {
    method: 'POST',
    data: payload,
  })

export const apiUpdateAnnouncement = (id, payload) =>
  apiClientRequest(`/announcements/${id}`, {
    method: 'PUT',
    data: payload,
  })

export const apiUpdateAnnouncementEnabled = (id, enabled) =>
  apiClientRequest(`/announcements/${id}/enable`, {
    method: 'PATCH',
    data: {
      enabled,
    },
  })

export const apiDeleteAnnouncement = (id, enabled) =>
  apiClientRequest(`/announcements/${id}`, {
    method: 'DELETE',
    data: {
      enabled,
    },
  })
