import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import ABTestingConfigContainer from '../pages/ABTestingConfig/ABTestingConfigContainer';
import ABTestingExperimentsContainer from '../pages/ABTestingExperiments/ABTestingExperimentsContainer';

const ChangePasswordEmailUserRoutes = () => (
  <AppAccessSwitch role="SALESMAN">
    <Route path="/ab-testing/config">
      <ABTestingConfigContainer />
    </Route>
    <Route path="/ab-testing/experiments">
      <ABTestingExperimentsContainer />
    </Route>
  </AppAccessSwitch>
);

export default ChangePasswordEmailUserRoutes;
