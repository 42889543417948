import apiClientRequest from '../../common/apiclient';

export const apiRestoreActivity = (activityId, backupURL, contactId, reconstruct = false) =>
  apiClientRequest(`/corrupted-activities/rhlab-restore/${activityId}`, {
    method: 'POST',
    data: {
      backupURL,
      contactId,
      reconstruct,
    },
  });

export const apiGetActivitiesStatuses = (ids) =>
  apiClientRequest('/corrupted-activities/rhlab-activities-statuses/', {
    method: 'POST',
    data: {
      ids,
    },
  });
