import React from 'react';
import styled from 'styled-components';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import Pagination from '../../../ui/Pagination';
import LargeBox from '../../../ui/LargeBox';
import printDate from '../../../common/lib/print-date';
import TooltipLink from '../../../ui/TooltipLink';
import getSubscriptionStatus from '../../../common/lib/get-subscription-status';
import subscriptionHasExpired from '../../../common/lib/subscription-has-expired';
import { useAddPropertiesToQueryString } from '../../../common/hooks/use-query';

const subStatusColors = {
  'ACTIVE': 'green',
  'PROCESSING': 'blue',
  'WAITING_FOR_PAYMENT': 'blue',
};

const SubscriptionRow = styled.tr`
  color: ${({ subscriptionStatus }) => subStatusColors[subscriptionStatus] || 'red'};
`;

const SubscriptionStatus = styled.td`
  text-transform: lowercase;
`;

const SubscriptionsList = ({
  onRefresh, total, subscriptions, currentPage, currentItemsPerPage, goToPage, onCreateSubscriptionClick,
  onTerminateSubscriptionClick
}) => {
  const query = useAddPropertiesToQueryString();

  return (
    <LargeBox heading="Subscriptions">
      <ContentNavBar>
        <NavBarActions>
          <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
        </NavBarActions>
        <NavBarActions>
          <TooltipButton icon="add" text="add" onClick={onCreateSubscriptionClick} />
        </NavBarActions>
      </ContentNavBar>
      <Table isEmpty={total === 0} isEmptyComponent="There are no subscriptions. Please click on + to add one">
        <thead>
        <tr>
          <th className="text-cell">Plan</th>
          <th className="text-cell">Status</th>
          <th className="date-cell">Started At</th>
          <th className="date-cell">Finished At</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {
          subscriptions.map(subscription => (
            <SubscriptionRow subscriptionStatus={getSubscriptionStatus(subscription)} key={subscription._id}>
              <td className="text-cell">{ subscription.subscriptionPlan.name }</td>
              <SubscriptionStatus className="text-cell">{ getSubscriptionStatus(subscription) }</SubscriptionStatus>
              <td className="date-cell">{ printDate(subscription.activationDate) }</td>
              <td className="date-cell">{ printDate(subscription.expirationDate) }</td>
              <TableActionCell>
                <TooltipLink
                  icon="visibility"
                  text="details"
                  to={`?${query([
                    ['action', 'subscription-details'],
                    ['subscriptionId', subscription._id]
                  ])}`}
                />
                {
                  subscription.subscriptionPlan.type === 'FREE' && subscription.status === 'ACTIVE' &&
                  !subscriptionHasExpired(subscription) && (
                    <TooltipLink
                      icon="edit"
                      text="edit"
                      to={`?${query([
                        ['action', 'edit-subscription'],
                        ['subscriptionId', subscription._id]
                      ])}`}
                    />
                  )
                }
                {
                  subscription.status !== 'CANCELLED' && (
                    <TooltipButton
                      icon="stop"
                      text="cancel"
                      onClick={() => onTerminateSubscriptionClick(subscription._id)}
                    />
                  )
                }
              </TableActionCell>
            </SubscriptionRow>
          ))
        }
        </tbody>
      </Table>
      <TableFooter>
        <Pagination
          currentPage={currentPage}
          currentItemsPerPage={currentItemsPerPage}
          onPageClick={goToPage}
          total={total}
        />
      </TableFooter>
    </LargeBox>
  );
};

export default SubscriptionsList;
