import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';

const FormWrapper = styled.div`
  min-width: 400px;
  max-width: 768px;
  overflow: hidden;
`;

const FormDialog = ({ children, ...props }) => (
  <Dialog
    {...props}
  >
    <FormWrapper>{ children }</FormWrapper>
  </Dialog>
);

export default FormDialog;
