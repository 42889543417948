import apiClientRequest from '../../common/apiclient';

export const apiSaveOptimizeExperiment = ({
  disabled,
  experimentId,
}) => apiClientRequest('/settings/google-optimize/experiments', {
  method: 'POST',
  data: {
    name: 'DEMO_SPACE',
    disabled,
    experimentId,
  },
})

export const apiGetOptimizeExperiments = () => apiClientRequest('/settings/google-optimize/experiments');
