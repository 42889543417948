import React from 'react';
import ABTestingConfig from './ABTestingConfig';
import { NotificationManager } from 'react-notifications';
import { apiSaveOptimizeCode } from './api';
import { apiGetGoogleOptimizeSettings } from '../Google/api';

const ABTestingConfigContainer = () => {
  const [googleOptimizeSettings, setGOSettings] = React.useState(null);
  const handleSave = React.useCallback(async ({ optimizeCode, disabled }) => {
    try {
      await apiSaveOptimizeCode({
        code: optimizeCode,
        disabled: disabled
      });
      NotificationManager.success('Your changes have been saved');
    } catch (err) {
      NotificationManager.error('Something went wrong, please try again later');
    }
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetGoogleOptimizeSettings();
        setGOSettings(result);
      } catch (err) {
        NotificationManager.error('Something went wrong, please try again later');
      }
    };

    fetch();
  }, []);

  return (
    <ABTestingConfig settings={googleOptimizeSettings} onSubmit={handleSave} />
  );
};

export default ABTestingConfigContainer;
