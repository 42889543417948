import React from 'react';
import { Formik } from 'formik';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import FormikRichText from '../../ui/formik/FormikRichText';
import FormikTextInput from '../../ui/FormikTextInput';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';
import FormDialog from '../../ui/FormDialog';
import Tab from '@material-ui/core/Tab';
import * as yup from 'yup';
import SectionLoader from '../../ui/SectionLoader';
import { TabContext, TabPanel } from '../../shared/TabContext';
import Tabs from '@material-ui/core/Tabs';

const validationSchema = yup.object({
  titleEn: yup.string().required('Field required'),
  contentEn: yup.string().required('Field required'),
});

const AnnouncementDialog = ({ title, initialValues, onClose, isLoading, onSave }) => {
  const [value, setValue] = React.useState("1");
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        titleEn: '',
        contentEn: '',
        titleFr: '',
        contentFr: '',
        titleEs: '',
        contentEs: '',
        ...initialValues,
      }}
      onSubmit={onSave}
    >
      {({ handleSubmit }) => (
        <FormDialog
          open
          title={title}
          closeDialog={onClose}
          buttons={[
            { type: 'ok', text: 'save', onClick: handleSubmit },
            { type: 'cancel', text: 'cancel' }
          ]}
        >
          {isLoading && <SectionLoader />}
          <TabContext value={value}>
            <Tabs onChange={(e, newValue) => setValue(newValue)}>
              <Tab label="English" value="1" />
              <Tab label="French" value="2" />
              <Tab label="Spanish" value="3" />
            </Tabs >
            <TabPanel value="1">
              <FormGroup>
                <Label>Title</Label>
                <FormikTextInput name="titleEn" />
                <CustomErrorMessage name="titleEn" />
              </FormGroup>
              <FormGroup>
                <Label>Content</Label>
                <FormikRichText name="contentEn" />
                <CustomErrorMessage name="contentEn" />
              </FormGroup>
            </TabPanel>
            <TabPanel value="2">
              <FormGroup>
                <Label>Titre</Label>
                <FormikTextInput name="titleFr" />
                <CustomErrorMessage name="titleFr" />
              </FormGroup>
              <FormGroup>
                <Label>Contenu</Label>
                <FormikRichText name="contentFr" />
                <CustomErrorMessage name="contentFr" />
              </FormGroup>
            </TabPanel>
            <TabPanel value="3">
              <FormGroup>
                <Label>Título</Label>
                <FormikTextInput name="titleEs" />
                <CustomErrorMessage name="titleEs" />
              </FormGroup>
              <FormGroup>
                <Label>Contenido</Label>
                <FormikRichText name="contentEs" />
                <CustomErrorMessage name="contentEs" />
              </FormGroup>
            </TabPanel>
          </TabContext>
        </FormDialog>
      )}
    </Formik>
  )
};

export default AnnouncementDialog;
