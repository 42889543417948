import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import Breadcrumb from '../../../../ui/Breadcrumb';
import LargeBox from '../../../../ui/LargeBox';
import TooltipButton from '../../../../ui/TooltipButton';
import ClearableInput from '../../../../ui/formik/ClearableInput';
import { ContentNavBar, NavBarActions } from '../../../../ui/ContentNavBar';
import { Table, TableFooter } from '../../../../ui/Table';
import { Row } from '../../../../ui/Flex';
import Pagination from '../../../../ui/Pagination';
import DeleteConfirmationDialog from '../../../../ui/DeleteConfirmationDialog';

import { usePaginate } from '../../../../common/hooks/use-paginate-deprecated';
import useQuery from '../../../../common/hooks/use-query';
import { useDeleteItem } from '../../../../common/hooks/use-delete-item';
import useOperation from '../../../../common/hooks/use-operation';
import { apiGetWorkflowTemplatesDir, apiDeleteDir } from '../../api';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TooltipLink from '../../../../ui/TooltipLink';
import SectionLoader from '../../../../ui/SectionLoader';

const FilterForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
  vertical-align: bottom
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
  vertical-align: bottom
`;

const WorkflowTemplatesDirs = ({ history }) => {

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const query = useQuery();

  const getSearchTerm = useCallback(() => {
    return query.get('search') || '';
  }, [query]);

  const getOrderBy = useCallback(() => {
    return query.get('orderBy') || '';
  }, [query]);

  const getOrder = useCallback(() => {
    return query.get('order') || '';
  }, [query]);

  const search = getSearchTerm();

  const [
    workflowTemplatesDirs,
    page,
    itemsPerPage,
    goToPage,
    onReload,
    getWorkflowTemplatesDirsByFilter
  ] = usePaginate(apiGetWorkflowTemplatesDir, {
    listPath: (page, itemsPerPage) => `/workflows/templateDirs?search=${search}&page=${page}&itemsPerPage=${itemsPerPage}&order=${getOrder()}&orderBy=${getOrderBy()}`,
    filters: {
      search: getSearchTerm(),
      orderBy: getOrderBy(),
      order: getOrder(),
    },
  });

  const [
    DirDeletion,
    deleteDir
  ] = useOperation(apiDeleteDir);

  const [
    deleteTemplateDirDialogRef,
    onDeleteClick,
    onDeleteTemplateDir
  ] = useDeleteItem(deleteDir);

  useEffect(() => {
    if (DirDeletion.success) {
      onReload();
    }
    getWorkflowTemplatesDirsByFilter({ page, itemsPerPage }, { search, order, orderBy });
  }, [orderBy, order, DirDeletion.success]);

  const tableTH = useCallback((columnName, _orderBy, aling = 'leftAlign') => {
    return (
      <th className={aling} onClick={() => setOrderBy(_orderBy)}>
        {
          orderBy === _orderBy ? (
            order === 'desc' ? <StyledArrowDownwardIcon onClick={() => setOrder('asc')} /> : <StyledArrowUpwardIcon onClick={() => setOrder('desc')} />
          ) : null
        }
        {columnName}
      </th>
    )
  }, [orderBy, order])

  const applyFilter = useCallback(({ search }) => {
    const params = [];
    if (search) {
      params.push(`search=${search}`);
    }

    if (page) {
      params.push(`page=${page}`)
    }

    if (itemsPerPage) {
      params.push(`itemsPerPage=${itemsPerPage}`)
    }

    history.push(`?${params.join('&')}`);

    getWorkflowTemplatesDirsByFilter({ page, itemsPerPage }, { search, order, orderBy });
  }, [page, itemsPerPage]);

  return (
    <div>
      <Breadcrumb
        items={[
          { title: 'templates directories' },
        ]}
      />
      <LargeBox heading="Templates directories">
        <ContentNavBar>
          <NavBarActions>
            <TooltipButton icon="refresh" text="refresh" onClick={onReload} />
          </NavBarActions>
          <NavBarActions>
            <Formik
              initialValues={{
                search
              }}
              onSubmit={applyFilter}
            >
              {
                ({ handleSubmit }) => (
                  <FilterForm onSubmit={handleSubmit}>
                    <ClearableInput
                      name="search"
                      type="text"
                      placeholder="Search ..."
                    />
                    <TooltipButton onClick={handleSubmit} icon="search" text='search' />
                  </FilterForm>
                )
              }
            </Formik>
            <TooltipLink icon="add" text="new workflow" to="./templateDirs/add" />
          </NavBarActions>
        </ContentNavBar>
        <Table
          isEmpty={workflowTemplatesDirs.data.length === 0}
          isEmptyComponent="Sorry, there are no templates directories. To create one click on the add button."
        >
          <thead>
          <tr>
            {tableTH('Name', 'name', 'leftAlign')}
            {tableTH('Description', 'description', 'leftAlign')}
            {tableTH('Doc URL', 'docUrl', 'leftAlign')}
            {tableTH('Lang', 'lang', 'leftAlign')}
            {tableTH('Default', 'isDefault', 'leftAlign')}
            <th className="leftAlign">actions</th>
          </tr>
          </thead>
          <tbody>
          {
            workflowTemplatesDirs && workflowTemplatesDirs.data.map((dir) => (
              <tr key={dir._id}>
                <td className="leftAlign">{dir.name}</td>
                <td className="leftAlign">{dir.description}</td>
                <td className="leftAlign">{dir.docUrl}</td>
                <td className="leftAlign">{dir.lang}</td>
                <td className="leftAlign">{dir.isDefault ? 'default' : '-'}</td>
                <td className="centerAlign">
                  <Row>
                    <TooltipLink icon="list_alt" text="templates list" to={`/workflows/templateDirs/${dir._id}`} />
                    <TooltipLink to={`/workflows/templateDirs/edit/${dir._id}`} icon="edit" text="Edit" />
                    <TooltipButton icon="close" text="remove" onClick={() => onDeleteClick({ id: dir._id })} />
                  </Row>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
        {(workflowTemplatesDirs.loading || workflowTemplatesDirs.loading) && <SectionLoader />}
        <TableFooter>
          <Pagination
            currentPage={page}
            currentItemsPerPage={itemsPerPage}
            onPageClick={goToPage}
            total={workflowTemplatesDirs.total}
          />
        </TableFooter>
      </LargeBox>
      <DeleteConfirmationDialog
        ref={deleteTemplateDirDialogRef}
        title="Are You Sure Want To Delete?"
        message="This will delete directory."
        onConfirm={onDeleteTemplateDir}
      />
    </div>
  )
};

export default WorkflowTemplatesDirs;
