import React from 'react';
import moment from 'moment';
import { Field } from 'formik';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:before, &:after': {
      display: 'none',
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #acacac',
    padding: '7px 10px',
    height: '35px',
    boxSizing: 'border-box',
  },
}));

const FormikDateTimePicker = ({ name, format = 'DD-MM-YYYY hh:mm:ss a' }) => {
  const classes = useStyles();
  return (
    <Field name={name}>
      {
        ({ field, form }) => (
          <KeyboardDateTimePicker
            {...field}
            value={field.value || null}
            format={format}
            onChange={(event, date) => {
              form.setFieldValue(name, moment(date, format).toDate());
            }}
            okLabel="Ok"
            cancelLabel="Cancel"
            InputProps={{
              className: classes.input,
              disableUnderline: true,
            }}
          />
        )
      }
    </Field>
  );
};

export default FormikDateTimePicker;
