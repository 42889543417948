import React from 'react';
import styled from "styled-components";
import MaterialFormGroup from "@material-ui/core/FormGroup";

const FormGroup = styled(MaterialFormGroup)`
  &.form-group {
    padding: 15px 0;
  }
`;

export default (props) => (
  <FormGroup classes={{
    root: 'form-group'
  }} {...props} />
);
