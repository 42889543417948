import apiClientRequest from '../../common/apiclient';

export const apiGetCorruptedActivitiesRestorableFromBackup = ({ backupURL }) =>
  apiClientRequest('/corrupted-activities/restorable-activities-from-backup', {
    params: {
      backupURL,
    }
  });

export const apiRestoreCorruptedActivityFromBackup = ({ id, backupURL }) =>
  apiClientRequest(`/corrupted-activities/${id}/restore-activity-from-backup`, {
    method: 'POST',
    data: {
      backupURL,
    },
  });
