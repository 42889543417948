import React from 'react';
import { Form, Formik } from 'formik';
import Stack from '../../ui/Stack';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { FormikRadio } from '../../ui/FormikRadio';
import LargeBox from '../../ui/LargeBox';
import Button from '@material-ui/core/Button';
import { Row } from '../../ui/Flex';
import { Link, useRouteMatch } from 'react-router-dom';

const FeaturesIntrosConfig = ({ initialValues, onSubmit }) => {
  const match = useRouteMatch();

  return (
    <LargeBox heading="Features Intros configuration">
      <Row justifyContent="flex-end">
        <Link to={`${match.url}/add`}>
          <Button variant="contained" color="primary" size="small">
            Configure
          </Button>
        </Link>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          teamBoardEnabled: false,
          dashboardEnabled: false,
          contactsEnabled: false,
          dataImportExportEnabled: false,
          mailingEnabled: false,
          customizationEnabled: false,
          mailBoxEnabled: false,
          ...initialValues,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Stack.Column spacing={1}>
              <FormGroup>
                <FormLabel htmlFor="teamBoardEnabled">
                  Team board
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="teamBoardEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="teamBoardEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="dashboardEnabled">
                  Dashboard
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="dashboardEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="dashboardEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="contactsEnabled">
                  Contacts
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="contactsEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="contactsEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="dataImportExportEnabled">
                  Import / Export
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="dataImportExportEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="dataImportExportEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="mailingEnabled">
                  Mailing
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="mailingEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="mailingEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="mailBoxEnabled">
                  Mail box
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="mailBoxEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="mailBoxEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="customizationEnabled">
                Customization
                </FormLabel>
                <Stack.Row spacing={1}>
                  <FormikRadio name="customizationEnabled" value={true}>Yes</FormikRadio>
                  <FormikRadio name="customizationEnabled" value={false}>No</FormikRadio>
                </Stack.Row>
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                style={{ alignSelf: 'flex-start' }}>
                Save
              </Button>
            </Stack.Column>
          </Form>
        )}
      </Formik>
    </LargeBox>
  );
};

export default FeaturesIntrosConfig;
