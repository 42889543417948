import React from 'reactn';
import Box from '@material-ui/core/Box';
import MessageDialog from '../ui/MessageDialog';
import SectionLoader from '../ui/SectionLoader';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import useDeleteByTextConfirmationHandlers from './useDeleteByTextConfirmationHandlers';

const RemoveObjectDialog = ({ title, isLoading, onClose, onConfirm }) => {
  const [isConfirmed, handleConfirmation] = useDeleteByTextConfirmationHandlers();

  return (
    <MessageDialog
      title={title}
      open
      closeDialog={onClose}
      buttons={[
        { type: 'ok', text: "Confirm", onClick: onConfirm, disabled: !isConfirmed },
        { type: 'cancel', text: "Cancel" }
      ]}>
      {isLoading && <SectionLoader />}
      <Box mb={2}>
        <Typography variant="body2">
          Are you sure? This actions is irreversible
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">
          Enter "delete" to confirm deletion
        </Typography>
      </Box>
      <Input fullWidth onChange={handleConfirmation} />
    </MessageDialog>
  );
};

export default RemoveObjectDialog;
