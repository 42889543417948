import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import ContactTemplatesListContainer from '../pages/ContactTemplates/containers/ContactTemplatesListContainer';

const ContactTemplatesRoutes = () => (
  <AppAccessSwitch role="SALESMAN">
      <Route path="/contact-templates" component={ContactTemplatesListContainer} />

  </AppAccessSwitch>
);

export default ContactTemplatesRoutes;
