import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Breadcrumb from '../../ui/Breadcrumb';
import LargeBox from '../../ui/LargeBox';
import MaterialIcon from '@material/react-material-icon';
import SectionLoader from '../../ui/SectionLoader';

const truncate = value => {
  if (typeof value === 'string') {
    return value.substr(0, 35);
  }

  return value;
};

const DatabaseCleanupList = ({
  onFixContactActivitiesWithDiffSpace,
  isLoading1,
  isLoading2,
  activitiesWithoutContacts,
  onCleanupContacts,
  activitiesWithContacts,
  onRefresh,
}) => {

  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { title: 'Deleted contacts to restore' },
        ]}
      />
      <LargeBox
        heading="Invalid activities"
      >
        <Box>
          Activité dont les contacts n'existe plus
        </Box>
        <Box pt={2} pb={2} mb={2} alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
          <IconButton size="small" onClick={onRefresh}>
            <MaterialIcon icon="refresh" />
          </IconButton>
          <Box flex="display" flexDirection="row">
            {activitiesWithoutContacts.length}
            <IconButton size="small" onClick={onCleanupContacts}>
              <MaterialIcon icon="delete" />
            </IconButton>
          </Box>
        </Box>
        <TableContainer style={{ height: 'calc(100vh - 300px)', position: 'relative'}} component={Paper}>
          {isLoading1 && <SectionLoader />}
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>Space</TableCell>
                <TableCell>Space owner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activitiesWithoutContacts
                .map(activity => (
                  <TableRow>
                    <TableCell>{activity._id}</TableCell>
                    <TableCell>{activity.createdAt}</TableCell>
                    <TableCell>{activity.updatedAt}</TableCell>
                    <TableCell>{truncate(activity.space.name)}</TableCell>
                    <TableCell>{truncate(activity.space.user.firstName)} {truncate(activity.space.user.lastName)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box m={5} />
        <Box>
          Activité dont les contacts spaces sont différents
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>
            {activitiesWithContacts.length}
          </div>
          <IconButton onClick={onFixContactActivitiesWithDiffSpace}>
            <MaterialIcon icon="build" />
          </IconButton>
        </Box>
        <TableContainer style={{ height: 'calc(100vh - 300px)', position: 'relative'}} component={Paper}>
          {isLoading2 && <SectionLoader />}
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Contact created at</TableCell>
                <TableCell>Contact updated at</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>Space</TableCell>
                <TableCell>Space owner</TableCell>
                <TableCell>Space contact</TableCell>
                <TableCell>Space contact owner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activitiesWithContacts
                .map(activity => (
                <TableRow>
                  <TableCell>{activity._id}</TableCell>
                  <TableCell>{activity.contact.email}</TableCell>
                  <TableCell>{activity.contact.firstName}</TableCell>
                  <TableCell>{activity.contact.lastName}</TableCell>
                  <TableCell>{truncate(activity.contact.company)}</TableCell>
                  <TableCell>{activity.contact.createdAt}</TableCell>
                  <TableCell>{activity.contact.updatedAt}</TableCell>
                  <TableCell>{activity.createdAt}</TableCell>
                  <TableCell>{activity.updatedAt}</TableCell>
                  <TableCell>{truncate(activity.space.name)}</TableCell>
                  <TableCell>{truncate(activity.space.user?.firstName)} {truncate(activity.space.user?.lastName)}</TableCell>
                  <TableCell>{truncate(activity.contact.space.name)}</TableCell>
                  <TableCell>{truncate(activity.contact.space.user?.firstName)} {truncate(activity.contact.space.user?.lastName)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LargeBox>
    </React.Fragment>
  )
};

export default DatabaseCleanupList;
