import apiClientRequest from '../../common/apiclient';

export const apiGetTeamBoardTemplates = ({ page, itemsPerPage, search }) =>
    apiClientRequest('/team-board-card-templates', {
        params: { page, itemsPerPage, search },
    });

export const apiSaveTeamBoardTemplate = (data) => apiClientRequest(`/team-board-card-templates`, {
    method: 'POST',
    data,
});

export const apiGetTeamBoardById = (id) => apiClientRequest(`/team-board-card-templates/${id}`);

export const apiDeleteTeamBoardById = (id) => apiClientRequest(`/team-board-card-templates/${id}`, { method: 'DELETE'});
