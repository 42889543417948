import React from 'react';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import { Route } from 'react-router-dom';
import AnnouncementsListContainer from '../pages/Announcements/AnnouncementsListContainer';

const AnnouncementsRoutes = () => (
  <AppAccessSwitch role="SALESMAN">
    <Route path="/announcements">
      <AnnouncementsListContainer />
    </Route>
  </AppAccessSwitch>
);

export default AnnouncementsRoutes;
