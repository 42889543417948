import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import SpacesList from '../components/SpacesList';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import {
  apiDisableAllReminders,
  apiDisableSpaceReminders, apiEnableAllReminders,
  apiEnableSpaceReminders,
  apiGetSpaces,
  apiGetUsers
} from '../api';
import useInternalError from '../../../shared/useInternalError';

const SpacesListContainer = () => {
  const internalError = useInternalError();
  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    spaces,
    setSpaces
  ] = useState([]);

  const [
    loading,
    setLoading
  ] = useState(false);

  const [
    total,
    setTotal
  ] = useState(0);

  const [
    users,
    setUsers
  ] = useState([]);

  const [
    spacesWithoutOwner,
    setSpacesWithoutOwner
  ] = useState(false);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch } = queryMap;

  const fetchSpaces = React.useCallback(async () => {
    setLoading(true);
    try {
      const { spaces, total } = await apiGetSpaces({
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        search: currentSearch,
        spacesWithoutOwner
      });

      setSpaces(spaces);
      setTotal(total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      internalError(err);
    }
  }, [currentPage, currentItemsPerPage, currentSearch, internalError, spacesWithoutOwner]);

  useEffect(() => {
    fetchSpaces();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchSpaces();
      }
    });
  }, [fetchSpaces, history]);

  const getUsersBySpace = (spaceId) => {
    const fetch = async () => {
      try {
        const result = await apiGetUsers(spaceId);
        setUsers(result);
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetch();
  }

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);

  const handleDisableAllReminders = React.useCallback(async () => {
    try {
      await apiDisableAllReminders();
      fetchSpaces();
      NotificationManager.success('All reminders have been disabled');
    } catch (err) {
      internalError(err);
    }
  }, [internalError, fetchSpaces]);


  const handleEnableAllReminders = React.useCallback(async () => {
    try {
      await apiEnableAllReminders();
      fetchSpaces();
      NotificationManager.success('All reminders have been enabled');
    } catch (err) {
      internalError(err);
    }
  }, [internalError, fetchSpaces]);

  const handleDisableSpaceReminders = React.useCallback(async (id) => {
    try {
      await apiDisableSpaceReminders(id);
      fetchSpaces();
      NotificationManager.success('changes saved');
    } catch (err) {
      internalError(err);
    }
  }, [internalError, fetchSpaces]);


  const handleEnableSpaceReminders = React.useCallback(async (id) => {
    try {
      await apiEnableSpaceReminders(id);
      fetchSpaces();
      NotificationManager.success('changes saved');
    } catch (err) {
      internalError(err);
    }
  }, [internalError, fetchSpaces]);

  return (
    <div>
      <Breadcrumb
        items={[
          { title: 'Spaces' }
        ]}
      />
      <SpacesList
        spaces={spaces}
        setSpaces={setSpaces}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        users={users}
        getUsersBySpace={getUsersBySpace}
        history={history}
        match={match}
        onSearchChange={onSearchChange}
        onEnableSpaceReminders={handleEnableSpaceReminders}
        onEnableAllReminders={handleEnableAllReminders}
        onDisableSpaceReminders={handleDisableSpaceReminders}
        onDisableAllReminders={handleDisableAllReminders}
        setSpacesWithoutOwner={setSpacesWithoutOwner}
        spacesWithoutOwner={spacesWithoutOwner}
        loading={loading}
      />
    </div>
  );
};

export default SpacesListContainer;
