import React from 'react';
import { useHistory } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableFooter, TableActionCell } from '../../../ui/Table';
import Pagination from '../../../ui/Pagination';
import LargeBox from '../../../ui/LargeBox';
import printDate from '../../../common/lib/print-date';
import SectionLoader from '../../../ui/SectionLoader';

const SuperUserSubscribersInvoices = ({ onRefresh, total, subscribers = [], currentPage, currentItemsPerPage, goToPage, loading, rangeDate, handleDateChange }) => {
  const history = useHistory();

  return (
    <LargeBox heading="Subscribers">
          <ContentNavBar>
            <NavBarActions>
              <TooltipButton icon="refresh" onClick={onRefresh} />
            </NavBarActions>
            <NavBarActions>
              <KeyboardDatePicker style={{ marginRight: '10px'}}
                autoOk={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-start-date"
                label="Start Date"
                value={rangeDate.startDate}
                onChange={(date)=>handleDateChange({ startDate: moment(date, 'YYYY-MM-DD').toDate()})}
              />

              <KeyboardDatePicker
                autoOk={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-end-date"
                label="End Date"
                value={rangeDate.endDate}
                onChange={(date)=>handleDateChange({ endDate: moment(date, 'YYYY-MM-DD').toDate() })}
              />
            </NavBarActions>
          </ContentNavBar>
          <Table isEmpty={total === 0} isEmptyComponent="There are no users. Please click on + to add one">
            <thead>
              <tr>
                <th className="text-cell">Full Name</th>
                <th className="text-cell">Email</th>
                <th className="text-cell">Amount Paid</th>
                <th className="date-cell">Created At</th>

              </tr>
            </thead>
            <tbody>
              {
                subscribers.map(subscriber => (
                  <tr key={subscriber.id}>
                    <td className="text-cell">{subscriber.lastName} {subscriber.firstName}</td>
                    <td className="text-cell">{subscriber.email}</td>
                    <td className="text-cell">€{(subscriber.invoices && subscriber?.invoices.map(({ total })=> (total / 100)).reduce((accumulator, a) => accumulator + a, 0) || 0).toFixed(2)}</td>
                    <td className="date-cell">{printDate(subscriber.createdAt)}</td>

                  </tr>
                ))
              }
            </tbody>
          </Table>
          <TableFooter>
            <Pagination
              currentPage={currentPage}
              currentItemsPerPage={currentItemsPerPage}
              onPageClick={goToPage}
              total={total}
            />
          </TableFooter>
          { loading && <SectionLoader /> }
    </LargeBox>
  )
};

export default SuperUserSubscribersInvoices;
