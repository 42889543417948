const FIX_NAME = {
  TEAM_BOARD_MIGRATION_0: 'Team board migration 1.0.0',
  INVITATION_MIGRATION_0: 'Invitation migration 1.0.0',
  CUSTOM_FIELDS_FIX_TYPE_0: 'Custom fields fix type 1.0.0',
  STRIPE_SYNC: 'Stripe synchronisation between prod and preprod',
  CREDIT_CARD_SYNC: 'Stripe credit cards',
  TEAM_BOARD_FIX_1_2_2: 'Team board Fix 1.2.2',
  FIX_ACTIVITY_FILE_NAMES: 'Fix Activity file names',
  USER_APP_STEPS_FIX: 'Improve user app completed steps model - 1.2.3',
  SET_DASHBOARDS_VISIBILITY: 'Set all dashboards visibilities',
  ADD_POSITION_FIELD_TO_DYNAMIC_COLUMNS: 'Add position field to dynamic fields',
  CREATE_SPACE_PARAMETERS: 'Create space parameters for all users',
  CREATE_GLOBAL_PARAMETERS: 'Create global parameters for all users',
  TRANSPORT_IMPORTED_FILES_TO_FILES_COLLECTION: 'Migrate all imported files to the file collection',
  ADD_FILES_TO_QUOTAS: 'Add files quotas to the subscriber\'s quotas usage',
  ADD_FILE_STORAGE_PLAN_TO_SUBSCRIPTIONS: 'Add fileStoragePlan to subscriptions',
  ADD_CUSTOM_FIELD_ID_TO_ADVANCED_FILTER: 'Add CustomField Id To Advanced Filter',
  FIX_INVITED_USERS_SPACES_QUOTA_125: 'Fix invited users spaces quota 1.2.5',
  ADD_TEAM_BOARD_QUOTA_USAGE_125: 'add team board quota variables 1.2.5',
  APPEND_EMAIL_CAMPAIGN_PLAN_TO_SUBSCRIPTIONS: 'Append Email Campaign Plan to Subscriptions',
  ADD_REPORTS_QUOTA_USAGE_125: 'add reports quota variables 1.2.5',
  CREATE_POSITIONS_125: 'create position 1.2.5',
  DELETE_PERMANENTLY_OBJECTS_1251: 'remove permanently undeleted objects 1.2.5',
  INITIALIZE_STATES_ACTIONS_125: 'initialize states actions 1.2.6',
  ALTER_TEAM_BOARD_CARD_ACTIVITY: 'Alter team bord card activities 1.2.6',
  FIX_LABELS_USING_IN_DIFFERENT_TEAM_BOARDS: 'Fix labels being used in different team boards 1.2.6',
  FILES_QUOTAS_SYNC_BTW_S3_AND_DB: 'Files quotas synchronisation between Amazon S3 and DB',
  CREATE_TEAM_BOARD_CARD_SEARCH_COLLECTION: 'create team board card search collection 1.2.6',
  REMOVE_INVITED_USER_SUBSCRIBER_INFORMATION_126: 'remove invited user subscriber information 1.2.6',
  CREATE_PIPELINE_COLUMNS_ORDER_FOR_EVERY_USER_IN_SPACE: 'create pipeline columns order for every user in space',
  FIX_HAYDAR_ACCOUNT_126: 'Fix haydar account 1.2.6 after user invitation',
  SET_ALL_PLANS_TO_EXPIRABLE_126: 'set all plans to expirable 1.2.6 (mis a jour de plan grauit illimité)',
  SET_WORKFLOW_ACTIVITY_DOMAINS_TO_DEFAULT_FALSE_126: 'set workflow directories / activity domains to false 1.2.6',
  RESET_APP_PROGRESS_MARGAUX_USER_126: 'delete duplicate margaux user 1.2.6',
  PATCH_REMINDER_ENABLE_DISABLE_SPACE_126: 'Patch reminder enable / disable feature 1.2.6',
  PATCH_CUSTOM_FIELDS_REMINDER_ENABLED_126: 'Patch custom fields reminder enabled 1.2.6',
  FEATURES_INTROS_CONFIG_CREATION_126: 'features intros config creation 1.2.6',
  ANNOUNCEMENTS_PATCH_126: 'Announcements Patch 1.2.6',
};

export default FIX_NAME;
