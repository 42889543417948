import React from 'react';
import AnnouncementsList from './AnnouncementsList';
import CreateAnnouncementContainer from './CreateAnnouncementContainer';
import { apiDeleteAnnouncement, apiGetAnnouncements, apiUpdateAnnouncementEnabled } from './api';
import { NotificationManager } from 'react-notifications';
import EditAnnouncementContainer from './EditAnnouncementContainer';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import RemoveObjectDialog from '../../shared/RemoveObjectDialog';

const AnnouncementsListContainer = () => {
  const [updateAnnouncement] = useAsyncOperation(apiUpdateAnnouncementEnabled);
  const [deleteAnnouncement, deletion] = useAsyncOperation(apiDeleteAnnouncement);
  const [announcements, setAnnouncements] = React.useState([]);
  const [createAnnouncementVisible, setCreateAnnouncementVisible] = React.useState(false);
  const [announcementToUpdate, setAnnouncementToUpdate] = React.useState(null);
  const [announcementToDelete, setAnnouncementToDelete] = React.useState(null);

  const handleDeleteAnnouncement = React.useCallback(async () => {
    try {
      await deleteAnnouncement(announcementToDelete);
      setAnnouncements(old => old.filter(a => a._id !== announcementToDelete));
      setAnnouncementToDelete(null);
    } catch (err) {
      NotificationManager.error('Something went wrong');
    }
  }, [announcementToDelete]);

  const handleSetAnnouncementEnabled = React.useCallback(async (id, enabled) => {
    try {
      await updateAnnouncement(id, enabled);
      setAnnouncements(old => old.map(c => {
        if (c._id === id) {
          return {
            ...c,
            enabled,
          };
        }

        return c;
      }))
    } catch (err) {
      NotificationManager.error('Something went wrong');
    }
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetAnnouncements();
        setAnnouncements(result);
      } catch (err) {
        NotificationManager.error('Something went wrong');
      }
    };

    fetch();
  }, []);

  return (
    <React.Fragment>
      <AnnouncementsList
        onCreateAnnouncement={() => setCreateAnnouncementVisible(true)}
        onEditAnnouncement={(announcement) => setAnnouncementToUpdate(announcement)}
        onDeleteAnnouncement={(id) => setAnnouncementToDelete(id)}
        onSetAnnouncementEnabled={(id, enabled) => handleSetAnnouncementEnabled(id, enabled)}
        announcements={announcements}
      />
      {createAnnouncementVisible && (
        <CreateAnnouncementContainer
          onSave={(newAnnouncement) => {
            setAnnouncements(old => old.map(x => ({
              ...x,
              enabled: false,
            })));
            setAnnouncements(old => [
              newAnnouncement,
              ...old,
            ]);
          }}
          onClose={() => setCreateAnnouncementVisible(false)}
        />
      )}
      {!!announcementToUpdate && (
        <EditAnnouncementContainer
          announcement={announcementToUpdate}
          onSave={(update) => {
            setAnnouncements(old => old.map(c => {
              if (c._id === update._id) {
                return update;
              }

              return c;
            }));
          }}
          onClose={() => setAnnouncementToUpdate(null)}
        />
      )}
      {announcementToDelete && (
        <RemoveObjectDialog
          title="Delete Announcement"
          isLoading={deletion.loading}
          onConfirm={handleDeleteAnnouncement}
          onClose={() => {
            setAnnouncementToDelete(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AnnouncementsListContainer;
