import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Breadcrumb from '../../../../ui/Breadcrumb';
import TooltipButton from '../../../../ui/formik/TooltipButton';
import LargeBox from '../../../../ui/LargeBox';
import FormGroup from '../../../../ui/FormGroup';
import Label from '../../../../ui/Label';
import { CustomErrorMessage } from '../../../../ui/formik/CustomErrorMessage';
import TextInput from '../../../../ui/formik/TextInput';
import SelectInput from '../../../../ui/formik/SelectInput';
import Checkbox from '../../../../ui/formik/Checkbox';
import { Button } from '@material-ui/core';
import useOperation from '../../../../common/hooks/use-operation';
import useGetAll from '../../../../common/hooks/use-get-all';
import { apiUpdateWorkflowTemplate, apiGetAllTemplatesDirs, apiGetTemplateById } from '../../api';
import each from 'lodash/each';
import CustomFieldListField from './CustomFieldListField';


const TextArea = styled(Field)`
  border: 1px solid #ddd;
  padding: 10px 5px;
  height: 70px;
  box-shadow: 0 1px 1px #ccc;
  margin: 7px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  resize: none;

  &::placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
  }

  &:focus {
    outline: 0;
  }
`;

export const templateSchema = yup.object({
  name: yup.string().required('the name is required'),
  description: yup.string(),
  configurations: yup.array().required('the configurations is required'),
  lang: yup.string().required('the lang is required'),
  docUrl: yup.string(),
  isActive: yup.boolean(),
  isDefault: yup.boolean(),
  workflowTemplateDir: yup.string().required('the template directory is required'),
});

const WorkflowTemplateEdit = ({ history, match }) => {
  const [templateConfigurations, setTemplateConfigurations] = useState([]);

  const [
    updatedTemplate,
    updateTemplate
  ] = useOperation(apiUpdateWorkflowTemplate);

  const [
    workflowTemplatesDirs,
  ] = useGetAll(apiGetAllTemplatesDirs);

  const [
    templateToEdit,
    getTemplateById
  ] = useOperation(apiGetTemplateById);

  useEffect(() => {
    getTemplateById(match.params.id);
  }, []);

  useEffect(() => {
    if (templateToEdit.data && templateToEdit.data.configurations) {
      setTemplateConfigurations(templateToEdit.data.configurations)
    }
  }, [templateToEdit.data.configurations]);

  const onSubmit = useCallback(({ name, description, lang, docUrl, usableInDemo, customFields, isDefault, isDefaultWorkflowDirectoryTemplate, workflowTemplateDir, configurations }) => {
    const confs = each(configurations , o => each(o, (v, k) => o[k] = v.trim()));
    updateTemplate(match.params.id, name, description, lang, docUrl, usableInDemo, isDefault, isDefaultWorkflowDirectoryTemplate, workflowTemplateDir, confs, customFields);
  }, [updateTemplate]);

  const cancel = () => {
    history.push(`/workflows/templates`);
  };

  const updateTemplateConfigurations = (index, field, e) => {
    templateConfigurations[index][field] = e.target.value;
    setTemplateConfigurations(templateConfigurations);
  }

  const removeConfiguration = (index) => {
    templateConfigurations.splice(index, 1);
    setTemplateConfigurations(templateConfigurations);
  }

  const addNewConfiguration = () => {
    templateConfigurations.push({
      currentState: '',
      action: '',
      nextState: '',
    })
  }

  return (
    <div>
      {
        updatedTemplate.success && (
          <Redirect to="/workflows/templates" />
        )
      }
      <Breadcrumb
        items={[
          { title: 'edit template' },
        ]}
      />
      <LargeBox heading="Edit Template">
        <Formik
          validationSchema={templateSchema}
          enableReinitialize
          initialValues={{
            name: (templateToEdit && templateToEdit.data) ? templateToEdit.data.name : '',
            description: (templateToEdit && templateToEdit.data) ? templateToEdit.data.description : '',
            configurations: templateConfigurations ? templateConfigurations : [],
            lang: (templateToEdit && templateToEdit.data) ? templateToEdit.data.lang : '',
            docUrl: (templateToEdit && templateToEdit.data) ? templateToEdit.data.docUrl : '',
            isDefault: (templateToEdit && templateToEdit.data) ? templateToEdit.data.isDefault : '',
            usableInDemo: (templateToEdit && templateToEdit.data) ? templateToEdit.data.usableInDemo : '',
            customFields: (templateToEdit && templateToEdit.data) ? templateToEdit.data.customFields || [] : [],
            workflowTemplateDir: (templateToEdit && templateToEdit.data) ? templateToEdit.data.workflowTemplateDir : '',
            isDefaultWorkflowDirectoryTemplate: (templateToEdit && templateToEdit.data) ? templateToEdit.data.isDefaultWorkflowDirectoryTemplate : ''
          }}
          onSubmit={onSubmit}
        >
          {
            ({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="workflowTemplateDir">Directory</Label>
                  <SelectInput
                    name="workflowTemplateDir"
                    fill
                  >
                    <option key="none" value="">Please choose a Template Directory</option>
                    {
                      workflowTemplatesDirs && workflowTemplatesDirs.data && workflowTemplatesDirs.data.map(dir => <option key={dir._id} value={dir._id}>{dir.name}</option>)
                    }
                  </SelectInput>
                  <CustomErrorMessage name="workflowTemplateDir" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <TextInput
                    name="name"
                    placeholder="Enter Name of directory"
                    fill
                  />
                  <CustomErrorMessage name="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">Description</Label>
                  <TextArea name="description" component="textarea" placeholder="Enter description" />
                  <CustomErrorMessage name="description" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="configurations">Configurations</Label>
                  {templateConfigurations.length === 0 && <Grid container spacing={2}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item>
                      <TooltipButton isLink icon="add" text="add new" onClick={() => addNewConfiguration()} />
                    </Grid>
                  </Grid>}
                  <Field
                  >
                    {({ form }) => (
                      values.configurations && values.configurations.map((configuration, key) =>
                        <Grid container spacing={2} key={key}>
                          <Grid item xs={3}>
                            <TextField
                              name="currentState"
                              label="Current State"
                              value={configuration.currentState}
                              onChange={(e) => {
                                form.handleChange(e);
                                updateTemplateConfigurations(key, 'currentState', e)
                              }}
                              fill
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              name="action"
                              label="Action"
                              value={configuration.action}
                              onChange={(e) => {
                                form.handleChange(e);
                                updateTemplateConfigurations(key, 'action', e)
                              }}
                              fill
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              name="nextState"
                              label="Next State"
                              value={configuration.nextState}
                              onChange={(e) => {
                                form.handleChange(e);
                                updateTemplateConfigurations(key, 'nextState', e)
                              }}
                              fill
                            />
                          </Grid>
                          <Grid item>
                            <TooltipButton isLink icon="close" text="remove" onClick={() => removeConfiguration(key)} />
                          </Grid>
                          {(key + 1) === templateConfigurations.length && <Grid item>
                            <TooltipButton isLink icon="add" text="add new" onClick={() => addNewConfiguration()} />
                          </Grid>}
                        </Grid>
                      )
                    )}
                  </Field>
                  <CustomErrorMessage name="configurations" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="docUrl">Documentation URL</Label>
                  <TextInput
                    name="docUrl"
                    placeholder="Enter Documentation URL"
                    fill
                  />
                  <CustomErrorMessage name="docUrl" />
                </FormGroup>
                <FormGroup>
                  <Label>Custom fields</Label>
                  <CustomFieldListField name="customFields" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="lang">Langage</Label>
                  <SelectInput
                    name="lang"
                    fill
                  >
                    <option key="none" value="">Please choose a langage</option>
                    <option key="en" value="en">English</option>
                    <option key="fr" value="fr">French</option>
                    <option key="es" value="es">Espagnole</option>
                  </SelectInput>
                  <CustomErrorMessage name="lang" />
                </FormGroup>
                <FormGroup>
                  <Checkbox name="usableInDemo" value={true} label="Use this property to create demo workspaces" />
                </FormGroup>
                <FormGroup>
                  <Checkbox name="isDefault" value={false} label="Visible" />
                </FormGroup>
                <FormGroup>
                  <Checkbox name="isDefaultWorkflowDirectoryTemplate" value={false} label="Default directory template" />
                </FormGroup>
                <Button type="submit" variant="contained" color="primary" className="text-white" >
                  update
                </Button>{' '}
                <Button variant="contained" className="text-white btn-danger" onClick={cancel}>
                  cancel
                </Button>
              </Form>
            )
          }
        </Formik>
      </LargeBox>
    </div>
  )
};

export default WorkflowTemplateEdit;
