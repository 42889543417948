import apiClientRequest from '../../common/apiclient';

export const apiGetCorruptedActivitiesUnrestorableWithContacts = () =>
  apiClientRequest('/corrupted-activities/lost-activities/with-contacts');

export const apiGetCorruptedActivitiesUnrestorableWithoutContacts = () =>
  apiClientRequest('/corrupted-activities/lost-activities/without-contacts');

export const apiDeleteCorruptActivity = (id) =>
  apiClientRequest(`/corrupted-activities/delete-activity/${id}`, {
    method: 'DELETE',
  });

export const apiCleanupActivities = () =>
  apiClientRequest(`/corrupted-activities/delete-corrupt-activities`, {
    method: 'DELETE',
  });

export const apiFixContactsActivitiesWithDifferentSpaces = () =>
  apiClientRequest('/corrupted-activities/fix-activities-contacts-with-different-space', {
    method: 'POST',
  });
