import React from 'react';
import { apiGetActivitiesToRestoreFromSearch, apiGetCorruptedActivitiesSearchRestoreResult, apiRestoreCorruptedActivitiesUsingSearch } from './api';
import CorruptedActivitiesList from './CorruptedActivitiesList';
import { NotificationManager } from 'react-notifications';
import MessageDialog from '../../ui/MessageDialog';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import SectionLoader from '../../ui/SectionLoader';

const CorruptedActivitiesListContainer = () => {
  const [activities, setActivities] = React.useState([]);
  const [contactsCount, setContactsCount] = React.useState(0);
  const [confirmed, setConfirmed] = React.useState(false);
  const [restoreResult, setRestoreResult] = React.useState(null);
  const [restoreCorruptedActivities, result2] = useAsyncOperation(apiRestoreCorruptedActivitiesUsingSearch);
  const [getCorruptedActivities, result1] = useAsyncOperation(apiGetActivitiesToRestoreFromSearch);
  const [getCorruptedActivitiesResult, result3] = useAsyncOperation(apiGetCorruptedActivitiesSearchRestoreResult);

  const handleRestore = React.useCallback(async () => {
    try {
      await restoreCorruptedActivities();
      NotificationManager.success('Restoration will be available soon');

      setConfirmed(false);
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, []);

  const fetchCorruptedActivitiesResult = React.useCallback(async () => {
    const result = await getCorruptedActivitiesResult();
    setRestoreResult(result);
  }, []);

  const fetchCorruptedActivities = React.useCallback(async () => {
    try {
      const result = await getCorruptedActivities();

      setActivities(result.activities);
      setContactsCount(result.contactsCount);
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, []);

  const handleRefresh = React.useCallback(() => {
    fetchCorruptedActivities();
    fetchCorruptedActivitiesResult();
  }, [fetchCorruptedActivities, fetchCorruptedActivitiesResult]);

  React.useEffect(() => {
    fetchCorruptedActivities();
    fetchCorruptedActivitiesResult();
  }, []);

  return (
    <React.Fragment>
      <CorruptedActivitiesList
        isLoading={result1.loading || result3.loading}
        activities={activities}
        contactsCount={contactsCount}
        onRestore={() => setConfirmed(true)}
        restoreResult={restoreResult}
        onRefresh={handleRefresh}
      />
      <MessageDialog
        open={confirmed}
        title="Confirmation"
        closeDialog={() => setConfirmed(false)}
        buttons={[
          { type: 'ok', text: 'Yes', onClick: handleRestore },
          { type: 'cancel', text: 'No' },
        ]}
      >
        {result2.loading && <SectionLoader />}
        Are you sure you want to proceed with the restoration?
      </MessageDialog>
    </React.Fragment>
  );
};

export default CorruptedActivitiesListContainer;
