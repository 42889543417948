import apiClientRequest from '../../common/apiclient';

export const apiUpdateFeaturesIntrosConfig = (data) =>
  apiClientRequest('/features-intros-config', {
    method: 'PUT',
    data,
  })

export const apiGetFeaturesIntrosConfig = () =>
  apiClientRequest('/features-intros-config')
