import apiClientRequest from '../../common/apiclient';

export const apiGetSpaces = ({ page, itemsPerPage, search, spacesWithoutOwner}) =>
    apiClientRequest('/spaces', {
        params: { page, itemsPerPage, search, spacesWithoutOwner },
    });

export const apiGetUsers = (spaceId) => apiClientRequest(`/users/${spaceId}`, {
    method: 'GET',
});

export const apiCheckQuotas = async (space, newOwner) => {
    return apiClientRequest(`/subscribers/quotas`, {
        method: 'POST',
        data: {
            space,
            newOwner,
        }
    });
};

export const apiChangeOwner = async (space, owner, newOwner) => {
    return apiClientRequest(`/spaces`, {
        method: 'PUT',
        data: {
            space,
            owner,
            newOwner,
        }
    });
};

export const apiUnsubscribeUserFromSpace = async (space, user) => {
    return apiClientRequest(`/users/unsubscribe-from-space`, {
        method: 'PUT',
        data: {
            space,
            user,
        }
    });
};

export const apiGetSubscriberSpacesDetails = (subscriberId) => apiClientRequest(`/subscribers/${subscriberId}/spaces`, {
    method: 'GET',
});

export const apiDeleteSpace = async (space) => {
    return apiClientRequest(`/spaces`, {
        method: 'DELETE',
        data: {
            space,
        }
    });
};

export const apiEnableAllReminders = () => apiClientRequest('/spaces/all/reminders/enable', {
    method: 'PATCH',
});

export const apiDisableAllReminders = () => apiClientRequest('/spaces/all/reminders/disable', {
    method: 'PATCH',
});

export const apiEnableSpaceReminders = (id) => apiClientRequest(`/spaces/${id}/reminders/enable`, {
    method: 'PATCH',
});

export const apiDisableSpaceReminders = (id) => apiClientRequest(`/spaces/${id}/reminders/disable`, {
    method: 'PATCH'
});
