import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Breadcrumb from '../../../../ui/Breadcrumb';
import LargeBox from '../../../../ui/LargeBox';
import FormGroup from '../../../../ui/FormGroup';
import Label from '../../../../ui/Label';
import { CustomErrorMessage } from '../../../../ui/formik/CustomErrorMessage';
import TextInput from '../../../../ui/formik/TextInput';
import SelectInput from '../../../../ui/formik/SelectInput';
import { Button } from '@material-ui/core';
import { apiGetTemplateDirById, apiUpdateWorkflowTemplateDir } from '../../api';
import { FormikRadio } from '../../../../ui/FormikRadio';
import useAsyncOperation from '../../../../common/hooks/use-async-operation';
import { NotificationManager } from 'react-notifications';
import SectionLoader from '../../../../ui/SectionLoader';
import useOperation from '../../../../common/hooks/use-operation';


const TextArea = styled(Field)`
  border: 1px solid #ddd;
  padding: 10px 5px;
  height: 70px;
  box-shadow: 0 1px 1px #ccc;
  margin: 7px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  resize: none;
  
  &::placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
  }
  
  &:focus {
    outline: 0;
  }
`;

export const templatesDirsSchema = yup.object({
  name: yup.string().required('the name is required'),
  description: yup.string(),
  lang: yup.string().required('the lang is required'),
  docUrl: yup.string(),
});

const WorkflowTemplateDirEdit = ({ history, match }) => {
  const [updateWorkflowTemplateDir, result] = useAsyncOperation(apiUpdateWorkflowTemplateDir);

  const [
    directoryToEdit,
    getDirectoryById
  ] = useOperation(apiGetTemplateDirById);

  useEffect(() => {
    getDirectoryById(match.params.id);
  }, []);

  const onSubmit = useCallback(async ({ name, description, lang, docUrl, isDefault }) => {
    try {
      await updateWorkflowTemplateDir(match.params.id, name, description, lang, docUrl, isDefault);
      history.push('/workflows/templateDirs');
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, [updateWorkflowTemplateDir]);

  const onCancel = () => {
    history.push(`/workflows/templateDirs`);
  };

  return (
    <div>
      <Breadcrumb
        items={[
          { title: 'update templates directories' },
        ]}
      />
      <LargeBox heading="Update Templates directories">
        {result.loading && <SectionLoader />}
        <Formik
          validationSchema={templatesDirsSchema}
          initialValues={{
            name: (directoryToEdit && directoryToEdit.data && directoryToEdit.data.name) || '',
            description: (directoryToEdit && directoryToEdit.data && directoryToEdit.data.description) || '',
            lang: (directoryToEdit && directoryToEdit.data && directoryToEdit.data.lang) || '',
            docUrl: (directoryToEdit && directoryToEdit.data && directoryToEdit.data.docUrl) || '',
            isDefault: (directoryToEdit && directoryToEdit.data && directoryToEdit.data.isDefault) || false,
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {
            ({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <TextInput
                    name="name"
                    placeholder="Enter Name of directory"
                    fill
                  />
                  <CustomErrorMessage name="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">Description</Label>
                  <TextArea name="description" component="textarea" placeholder="Enter description" />
                  <CustomErrorMessage name="description" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="docUrl">Documentation URL</Label>
                  <TextInput
                    name="docUrl"
                    placeholder="Enter Documentation URL"
                    fill
                  />
                  <CustomErrorMessage name="docUrl" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="lang">Langage</Label>
                  <SelectInput
                    name="lang"
                    fill
                  >
                    <option key="none" value="">Please choose a langage</option>
                    <option key="en" value="en">English</option>
                    <option key="fr" value="fr">French</option>
                    <option key="es" value="es">Espagnole</option>
                  </SelectInput>
                  <CustomErrorMessage name="lang" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="isDefault">
                    Default
                  </Label>
                  <FormikRadio name="isDefault" value={true}>Yes</FormikRadio>
                  <FormikRadio name="isDefault" value={false}>No</FormikRadio>
                </FormGroup>
                <Button type="submit" variant="contained" color="primary" className="text-white" >
                  update
                </Button>{' '}
                <Button variant="contained" className="text-white btn-danger" onClick={onCancel}>
                  cancel
                </Button>
              </Form>
            )
          }
        </Formik>
      </LargeBox>
    </div>
  )
};

export default WorkflowTemplateDirEdit;
