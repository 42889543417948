import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Stack from '../../ui/Stack';
import Breadcrumb from '../../ui/Breadcrumb';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { IconButton, TableBody, TableHead } from '@material-ui/core';
import printDate from '../../common/lib/print-date';
import Typography from '@material-ui/core/Typography';
import { Row } from '../../ui/Flex';
import { Delete, Edit } from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';

const AnnouncementsList = ({
  onCreateAnnouncement,
  onEditAnnouncement,
  onSetAnnouncementEnabled,
  onDeleteAnnouncement,
  announcements,
}) => {

  return (
    <Stack.Column spacing={2}>
      <Breadcrumb items={[{title: 'Announcements'}]} />
      <Button
        size="small"
        style={{alignSelf: 'flex-end'}}
        variant="contained"
        color="primary"
        onClick={onCreateAnnouncement}
        startIcon={<Add fontSize="small" />}>
        Announcement
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title (English)</TableCell>
              <TableCell align="left">Title (French)</TableCell>
              <TableCell align="left">Title (Spanish)</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements.map(announcement => (
              <TableRow key={announcement._id}>
                <TableCell align="left">{announcement.titleEn}</TableCell>
                <TableCell align="left">{announcement.titleFr}</TableCell>
                <TableCell align="left">{announcement.titleEs}</TableCell>
                <TableCell align="center">
                  <Switch
                    checked={announcement.enabled}
                    color={!announcement.enabled ? 'secondary' : 'primary'}
                    onClick={() => {
                      onSetAnnouncementEnabled(announcement._id, !announcement.enabled);
                    }}
                  />
                </TableCell>
                <TableCell align="center">{printDate(announcement.createdAt)}</TableCell>
                <TableCell align="center">
                  <Stack.Row spacing={1} justifyContent="center">
                    <IconButton onClick={() => onEditAnnouncement(announcement)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDeleteAnnouncement(announcement._id)}>
                      <Delete />
                    </IconButton>
                  </Stack.Row>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {announcements.length === 0 && (
          <Row alignItems="center" width="100%" justifyContent="center" height={200}>
            <Typography variant="body2" align="center">
              You don't have any announcement
            </Typography>
          </Row>
        )}
      </TableContainer>
    </Stack.Column>
  );
};

export default AnnouncementsList;
