import apiClientRequest from '../../common/apiclient';

export const apiUpdateSubscriptionPlan = (id, {
  name,
  type,
  defaultProducts,
  spacesQuota,
  activitiesQuota,
  workflowsQuota,
  filesGbPrice,
  freeFilesQuota,
  price,
  isDefault,
  visible,
  period,
  unit,
  unlimitedActivities,
  unlimitedWorkflows,
  unlimitedSpaces,
  unlimitedTeamBoardCards,
  teamBoardCardsQuota,
  unlimitedReports,
  reportsQuota,
  position,
  freeEmails,
  emailUnit,
  emailUnitPrice,
  unlimitedReportsQuota,
  expirable,
  unlimitedFreeEmail,
}) => apiClientRequest(`/edit-subscription-plan/${id}`, {
  method: 'PATCH',
  data: {
    name,
    type,
    defaultProducts,
    spacesQuota,
    activitiesQuota,
    workflowsQuota,
    filesGbPrice,
    freeFilesQuota,
    price,
    isDefault,
    visible,
    period,
    unit,
    unlimitedActivities,
    unlimitedReports,
    reportsQuota,
    unlimitedWorkflows,
    unlimitedSpaces,
    unlimitedTeamBoardCards,
    teamBoardCardsQuota,
    position,
    freeEmails,
    emailUnit,
    emailUnitPrice,
    unlimitedReportsQuota,
    expirable,
    unlimitedFreeEmail,
  }
});

export const getSubscriptionPlanById = (id) =>
  apiClientRequest(`/edit-subscription-plan/subscription-plans/${id}`, {
  method: 'GET',
});
