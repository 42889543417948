import apiClientRequest from '../../common/apiclient';

export const apiSaveOptimizeCode = ({code, disabled}) =>
  apiClientRequest('/settings/google-optimize', {
    method: 'POST',
    data: {
      code,
      disabled,
    },
  });
