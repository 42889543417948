import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

export const ContentNavBar = props => <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} {...props} />;

export const NavBarActions = props => <Box display="flex" flexDirection="row" alignItems="center" {...props} />

export const NavBarCheckbox = styled(FormControlLabel)`
  margin: 0;
`;
