import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import FeaturesIntrosConfigContainer from '../pages/FeaturesIntrosConfig/FeaturesIntrosConfigContainer';
import FeaturesIntrosMaker from '../pages/FeaturesIntrosMaker/FeaturesIntrosMaker';

const FeaturesIntrosRoutes = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.url}/add`}>
        <FeaturesIntrosMaker />
      </Route>
      <Route path={`${match.url}`}>
        <FeaturesIntrosConfigContainer />
      </Route>
    </>
  );
};

export default FeaturesIntrosRoutes;
