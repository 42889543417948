export const WORKFLOWS = "WORKFLOWS";
export const WORKFLOW_TEMPLATES = 'WORKFLOW_TEMPLATES';
export const WORKFLOW_TEMPLATE_DIRS = 'WORKFLOW_TEMPLATE_DIRS';
export const SUBSCRIPTION = "SUBSCRIPTION";
export const SUBSCRIBERS = "SUBSCRIBERS";
export const SUPER_USERS = 'SUPER_USERS';
export const LEGACY_USERS = 'LEGACY_USERS';
export const FIXES = 'FIXES';
export const SPACES = "SPACES";
export const OTHER_FIXES = 'OTHER_FIXES';
export const SETTINGS = "SETTINGS";
export const SETTING_CHAT = "SETTING_CHAT";
export const SETTING_GOOGLE_ANALYTICS = "SETTING_GOOGLE_ANALYTICS";
export const SETTING_GOOGLE_TAGS = "SETTING_GOOGLE_TAGS";
export const LOGS = 'LOGS';
export const EMAIL_CAMPAIGNS = 'EMAIL_CAMPAIGNS';
export const AB_TESTING = 'AB_TESTING';
export const AB_TESTING_CONFIG = 'AB_TESTING_CONFIG';
export const AB_TESTING_EXPERIMENTS = 'AB_TESTING_EXPERIMENTS';
export const EMAIL_CAMPAIGNS_TEMPLATES = 'EMAIL_CAMPAIGNS_TEMPLATES';
export const EMAIL_CAMPAIGNS_TEMPLATE_CATEGORIES = 'EMAIL_CAMPAIGNS_TEMPLATE_CATEGORIES';
export const EMAIL_CAMPAIGNS_TEMPLATE_TAGS = 'EMAIL_CAMPAIGNS_TEMPLATE_TAGS';
export const SEARCH_RESTORE = 'SEARCH_RESTORE';
export const RHLAB_RESTORE = 'RHLAB_RESTORE';
export const DELETED_CONTACTS_RESTORE = 'DELETED_CONTACTS_RESTORE';
export const DATABASE_CLEANUP = 'DATABASE_CLEANUP';
export const SETTING_OUTBOX = 'SETTING_OUTBOX';
export const CONTACT_TEMPLATES = 'CONTACT_TEMPLATES';
export const DEMO_SPACE = 'DEMO_SPACE';
export const TEAM_BOARD_CARD_TEMPLATES = 'TEAM_BOARD_CARD_TEMPLATES';
export const FEATURES_INTROS = 'FEATURES_INTROS';
export const SETTING_ANNOUNCEMENTS = 'SETTING_ANNOUNCEMENTS';

export const SIDE_MENU_ITEM_PATH_MAP = {
  [SUBSCRIPTION]: "/subscriptions",
  [WORKFLOWS]: "/workflows",
  [WORKFLOW_TEMPLATES]: '/workflows/templates',
  [WORKFLOW_TEMPLATE_DIRS]: '/workflows/templateDirs',
  [SUBSCRIPTION]: '/subscription-plans',
  [SUBSCRIBERS]: '/subscribers',
  [SUPER_USERS]: '/super-users',
  [LEGACY_USERS]: '/legacy-users',
  [SPACES]: '/spaces',
  [OTHER_FIXES]: '/other-fixes',
  [SETTINGS]: "/settings",
  [SETTING_CHAT]: "/settings/chat",
  [SETTING_GOOGLE_ANALYTICS]: "/settings/google-analytics",
  [SETTING_GOOGLE_TAGS]: "/settings/google-tags",
  [SETTING_ANNOUNCEMENTS]: "/announcements",
  [LOGS]: '/logs',
  [SEARCH_RESTORE]: '/corrupted-activities',
  [RHLAB_RESTORE]: '/restore-contacts',
  [DELETED_CONTACTS_RESTORE]: '/deleted-contacts-restore',
  [DATABASE_CLEANUP]: '/database-cleanup',
  [EMAIL_CAMPAIGNS]: '/email-campaigns',
  [EMAIL_CAMPAIGNS_TEMPLATES]: '/email-campaigns/templates',
  [EMAIL_CAMPAIGNS_TEMPLATE_CATEGORIES]: '/email-campaigns/template-categories',
  [EMAIL_CAMPAIGNS_TEMPLATE_TAGS]: '/email-campaigns/template-tags',
  [SETTING_OUTBOX]: '/settings/outboxes',
  [AB_TESTING_CONFIG]: '/ab-testing/config',
  [AB_TESTING_EXPERIMENTS]: '/ab-testing/experiments',
  [CONTACT_TEMPLATES]: '/contact-templates',
  [TEAM_BOARD_CARD_TEMPLATES]: '/team-board-card-templates',
  [FEATURES_INTROS]: '/features-intros-config',
};

function sideMenu (sideMenuItems, sideMenuItemsPathMap) {
  return sideMenuItems.map(sideMenuItem => {
    if (sideMenuItem.subSideMenuItems) {
      const sideMenuItemsIds = sideMenuItem.subSideMenuItems.map(({ id }) => id);
      return {
        id: sideMenuItem.id,
        icon: sideMenuItem.icon,
        title: sideMenuItem.title,
        roles: sideMenuItem.roles,
        path: sideMenuItemsPathMap[sideMenuItem.id],
        isDropdown: true,
        isOpen: selectedMenuItem =>
          sideMenuItemsIds.find(item => item === selectedMenuItem) !== undefined,
        subSideMenuItems: sideMenuItem.subSideMenuItems.map(sub => ({
          id: sub.id,
          title: sub.title,
          path: sideMenuItemsPathMap[sub.id],
          roles: sub.roles,
          isSelected: selectedMenuItem => selectedMenuItem === sub.id,
        }))
      };
    } else {
      return {
        id: sideMenuItem.id,
        icon: sideMenuItem.icon,
        title: sideMenuItem.title,
        roles: sideMenuItem.roles,
        path: sideMenuItemsPathMap[sideMenuItem.id],
        isSelected: selectedMenuItem => selectedMenuItem === sideMenuItem.id,
      };
    }
  })
}

export const SIDE_MENU_ITEMS = sideMenu([
  {
    id: SUBSCRIPTION,
    icon: "subscriptions",
    title: "Subscription Plans",
    roles: ['SALESMAN'],
  },
  {
    id: WORKFLOWS,
    icon: "account_tree",
    title: "workflows",
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: WORKFLOW_TEMPLATE_DIRS,
        title: 'Template Directories',
        roles: ['SALESMAN'],
      },
      {
        id: WORKFLOW_TEMPLATES,
        title: 'Templates',
        roles: ['SALESMAN'],
      },
    ]
  },
  {
    id: SUBSCRIBERS,
    icon: "account_box",
    title: "Subscribers",
    roles: ['SALESMAN'],
  },
  {
    id: SUPER_USERS,
    icon: 'people',
    title: 'Super Users',
    roles: ['ADMIN'],
  },
  {
    id: LEGACY_USERS,
    icon: 'people_outline',
    title: 'Legacy users',
    roles: ['ADMIN'],
  },
  {
    id: FIXES,
    icon: "warning",
    title: "Routines",
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: SEARCH_RESTORE,
        icon: 'warning',
        title: 'Search restore',
        roles: ['ADMIN'],
      },
      {
        id: RHLAB_RESTORE,
        icon: 'build',
        title: 'RHLAB restore',
        roles: ['ADMIN'],
      },
      {
        id: DELETED_CONTACTS_RESTORE,
        icon: 'warning',
        title: 'Deleted contacts restore',
        roles: ['ADMIN'],
      },
      {
        id: DATABASE_CLEANUP,
        icon: 'build',
        title: 'Database clean up',
        roles: ['ADMIN'],
      },
    ]
  },
  {
    id: SPACES,
    icon: "group_work",
    title: "Spaces",
    roles: ['SALESMAN'],
  },
  {
    id: OTHER_FIXES,
    icon: 'settings',
    title: 'Other Fixes',
    roles: ['ADMIN'],
  },
  {
    id: SETTINGS,
    icon: "settings_applications",
    title: "settings",
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: SETTING_CHAT,
        title: 'Chat',
        roles: ['SALESMAN'],
      },
      {
        id: SETTING_GOOGLE_ANALYTICS,
        title: 'Google Analytics',
        roles: ['SALESMAN'],
      },
      {
        id: SETTING_GOOGLE_TAGS,
        title: 'Google Tags',
        roles: ['SALESMAN'],
      },
      {
        id: SETTING_OUTBOX,
        title: 'Email Outbox expiration',
        roles: ['ADMIN'],
      },
      {
        id: FEATURES_INTROS,
        title: 'Features intros config',
        roles: ['ADMIN'],
      },
    ]
  },
  {
    id: SETTING_ANNOUNCEMENTS,
    title: 'Announcements',
    icon: 'announcement',
    roles: ['ADMIN'],
  },
  {
    id: AB_TESTING,
    icon: 'double_arrow',
    title: 'A/B Testing',
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: AB_TESTING_CONFIG,
        title: 'Configuration',
        roles: ['ADMIN'],
      },
      {
        id: AB_TESTING_EXPERIMENTS,
        title: 'Experiments',
        roles: ['ADMIN'],
      },
    ],
  },
  {
    id: EMAIL_CAMPAIGNS,
    icon: "drafts",
    title: "E-mail Campaigns",
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: EMAIL_CAMPAIGNS_TEMPLATES,
        title: 'Email Templates',
        roles: ['SALESMAN'],
      },
      {
        id: EMAIL_CAMPAIGNS_TEMPLATE_CATEGORIES,
        title: 'Template Categories',
        roles: ['SALESMAN'],
      },
      {
        id: EMAIL_CAMPAIGNS_TEMPLATE_TAGS,
        title: 'Template Tags',
        roles: ['SALESMAN'],
      },
    ]
  },
  {
    id: DEMO_SPACE,
    title: 'Demo space config',
    icon: 'contacts',
    roles: ['ADMIN'],
    subSideMenuItems: [
      {
        id: CONTACT_TEMPLATES,
        title: 'Contact templates',
        roles: ['SALESMAN'],
      },
      {
        id: TEAM_BOARD_CARD_TEMPLATES,
        title: 'Team board templates',
        roles: ['SALESMAN'],
      },
    ],
  },
  {
    id: LOGS,
    icon: 'description',
    title: 'Logs',
    roles: ['ADMIN'],
  },
], SIDE_MENU_ITEM_PATH_MAP);
