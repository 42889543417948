import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableFooter, TableActionCell } from '../../../ui/Table';
import Pagination from '../../../ui/Pagination';
import LargeBox from '../../../ui/LargeBox';
import printDate from '../../../common/lib/print-date';
import SpacesDetails from '../../SpacesList/components/SpacesDetails';
import TabsNavigation from '../../../ui/TabsNavigation';
import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';


const Tab = styled.div`
  display: block;
  flex: 1;
`;

const UsersTab = styled(Tab)``;
UsersTab.tabName = 'Users';
const SpacesTab = styled(Tab)``;
SpacesTab.tabName = 'Spaces';

const UsersList = ({ onRefresh, total, users, currentPage, currentItemsPerPage, goToPage, subscriberId, rangeDate, handleDateChange }) => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <LargeBox heading="Details">
      <TabsNavigation tabIndex={activeIndex} setTabIndex={setActiveIndex}>
        <UsersTab>
          <ContentNavBar>
            <NavBarActions>
              <TooltipButton icon="refresh" onClick={onRefresh} />
            </NavBarActions>
            <NavBarActions>
              <KeyboardDatePicker style={{ marginRight: '10px'}}
                autoOk={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-start-date"
                label="Start Date"
                value={rangeDate.startDate}
                onChange={(date)=>handleDateChange({ startDate: moment(date, 'YYYY-MM-DD').toDate()})}
              />

              <KeyboardDatePicker
                autoOk={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-end-date"
                label="End Date"
                value={rangeDate.endDate}
                onChange={(date)=>handleDateChange({ endDate: moment(date, 'YYYY-MM-DD').toDate() })}
              />
            </NavBarActions>
          </ContentNavBar>
          <Table isEmpty={total === 0} isEmptyComponent="There are no users. Please click on + to add one">
            <thead>
              <tr>
                <th className="text-cell">Full Name</th>
                <th className="text-cell">Email</th>
                <th className="text-cell">Amount Paid</th>
                <th className="date-cell">Created At</th>
                <th className="date-cell">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                users.map(subscriber => (
                  <tr key={subscriber.id}>
                    <td className="text-cell">{subscriber.lastName} {subscriber.firstName}</td>
                    <td className="text-cell">{subscriber.email}</td>
                    <td className="text-cell">€{(subscriber.invoices && subscriber?.invoices.map(({ total })=> (total / 100)).reduce((accumulator, a) => accumulator + a, 0) || 0).toFixed(2)}</td>
                    <td className="date-cell">{printDate(subscriber.createdAt)}</td>
                    <TableActionCell>
                      <TooltipButton icon="list_alt" text="Invoices"
                        onClick={()=> history.push(`subscriptions/${subscriber.subscriptions._id}/invoices?date=${Object.values(rangeDate).join('|')}`)}
                      />
                    </TableActionCell>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <TableFooter>
            <Pagination
              currentPage={currentPage}
              currentItemsPerPage={currentItemsPerPage}
              onPageClick={goToPage}
              total={total}
            />
          </TableFooter>
        </UsersTab>
        <SpacesTab>
          <SpacesDetails subscriberId={subscriberId} />
        </SpacesTab>
      </TabsNavigation>
    </LargeBox>
  )
};

export default UsersList;
