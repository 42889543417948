import React  from 'react';
import { Field, useField } from 'formik';
import RichText from '../RichText';

const FormikRichText = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const handleRichTextChange = React.useCallback((event, editor) => {
    const data = editor.getData();
    helpers.setValue(data);
  }, []);

  const handleRichTextBlur = React.useCallback(() => {
    helpers.setTouched();
  }, []);

  return (
    <RichText
      {...props}
      {...field}
      data={field.value}
      onChange={handleRichTextChange}
      onBlur={handleRichTextBlur}
    />
  );
};

export default FormikRichText;
