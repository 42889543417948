import React from 'react';
import SuperUsersListContainer from './containers/SuperUsersListContainer';
import { Route, Redirect } from 'react-router-dom';
import AddSuperUserContainer from '../AddSuperUser/containers/AddSuperUserContainer';
import EditSuperUserContainer from '../EditSuperUser/containers/EditSuperUserContainer';
import RemoveSuperUserContainer from '../RemoveSuperUser/containers/RemoveSuperUserContainer';
import AppAccessRoute from '../../common/components/AppAccessRoute';

const SuperUsersList = () => (
  <AppAccessRoute role="ADMIN">
    <Route path="/super-users">
      <SuperUsersListContainer />
    </Route>
    <Route path="/super-users/add">
      <AddSuperUserContainer />
    </Route>
    <Route path="/super-users/edit/:id">
      <EditSuperUserContainer />
    </Route>
    <Route path="/super-users/remove/:id">
      <RemoveSuperUserContainer />
    </Route>
    <Redirect to="/super-users" />
  </AppAccessRoute>
);

export default SuperUsersList;
