import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import FeaturesIntroMakerForm from './FeaturesIntroMakerForm';
import { apiUpdateFeatureIntroConfig } from './api';
import { NotificationManager } from 'react-notifications';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import SectionLoader from '../../ui/SectionLoader';
import { apiGetFeaturesIntrosConfig } from '../FeaturesIntrosConfig/api';
import FormDialog from '../../ui/FormDialog';
import { TabContext, TabPanel } from '../../shared/TabContext';


const FeaturesIntrosMaker = () => {
  const [featuresIntrosConfig, setFeatureIntroConfig] = React.useState(null);
  const [tab, setTab] = React.useState('dashboard');
  const history = useHistory();
  const [updateFeatureIntroConfig, update] = useAsyncOperation(apiUpdateFeatureIntroConfig);
  const [getFeatureIntroConfig, get] = useAsyncOperation(apiGetFeaturesIntrosConfig);

  const handleSave = React.useCallback((featureIntro) => async ({ config, locale }) => {
    try {
      await updateFeatureIntroConfig({
        featureIntro,
        config,
        locale,
      });
      NotificationManager.success('Changes saved');
    } catch (err) {
      NotificationManager.error('Something went wrong, please try again later');
    }
  }, [updateFeatureIntroConfig]);

  React.useEffect(() => {
    const fetch = async () => {
      const result = await getFeatureIntroConfig();

      setFeatureIntroConfig(result);
    };

    fetch();
  }, []);

  return (
    <FormDialog
      open
      title="Configure features intros"
      closeDialog={() => history.goBack()}
      buttons={[
        { type: 'cancel', text: 'close' },
      ]}
    >
      {(update.loading || get.loading)  && <SectionLoader />}
      <TabContext value={tab}>
        <Box style={{borderBottom: '1px solid #333'}}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab value="dashboard" label="Dashboard" />
            <Tab value="contacts" label="Contacts" />
            <Tab value="mailing" label="Campaigns & Mailing" />
            <Tab value="teamBoard" label="Team board" />
            <Tab value="dataImportExport" label="Import & Export" />
            <Tab value="customization" label="Customization" />
            <Tab value="mailBox" label="Mailbox" />
          </Tabs>
        </Box>
        <TabPanel value="dashboard">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.dashboard} onSave={handleSave('dashboard')} />
        </TabPanel>
        <TabPanel value="contacts">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.contacts} onSave={handleSave('contacts')} />
        </TabPanel>
        <TabPanel value="mailing">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.mailing} onSave={handleSave('mailing')} />
        </TabPanel>
        <TabPanel value="teamBoard">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.teamBoard} onSave={handleSave('teamBoard')} />
        </TabPanel>
        <TabPanel value="dataImportExport">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.dataImportExport} onSave={handleSave('dataImportExport')} />
        </TabPanel>
        <TabPanel value="customization">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.customization} onSave={handleSave('customization')} />
        </TabPanel>
        <TabPanel value="mailBox">
          <FeaturesIntroMakerForm config={featuresIntrosConfig?.mailBox} onSave={handleSave('mailBox')} />
        </TabPanel>
      </TabContext>
    </FormDialog>
  )
};

export default FeaturesIntrosMaker;
