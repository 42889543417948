import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import TextInput from '../../../ui/formik/TextInput';
import SelectInput from '../../../ui/formik/SelectInput';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import SectionLoader from '../../../ui/SectionLoader';
import { useQueryMap } from '../../../common/hooks/use-query';

const validationSchema = yup.object({
    title: yup.string(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    position: yup.string(),
    company: yup.string(),
    phone: yup.string(),
    mobile: yup.string(),
    web: yup.string(),
});

const ContactTemplateForm = ({ title, contact, save, open, cancel, loading }) => {
  const history = useHistory();
  const queryMap = useQueryMap();
  const submit = React.useCallback(submitOnce(save), [save]);
  const { id } = queryMap;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        title: contact?.title || '',
        firstName: contact?.firstName || '',
        lastName: contact?.lastName || '',
        email: contact?.email || '',
        position: contact?.position || '',
        company: contact?.company || '',
        phone: contact?.phone || '',
        mobile: contact?.mobile || '',
        web: contact?.web || '',
      }}
      enableReinitialize
      onSubmit={submit}>
      {
        ({ handleSubmit }) => (
          <FormDialog
            open={open}
            title={title}
            closeDialog={cancel}
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' }
            ]}
            onExit={ () => history.push('contact-templates') }
          >
            {
              loading && (
                <SectionLoader />
              )
            }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="title">
                    Title
                </Label>
                <SelectInput
                    name="title"
                    fill='true'
                >
                    <option key="1" value=""></option>
                    <option key="2" value='Mr.'>Mr.</option>
                    <option key="3" value='Mrs.'>Mrs.</option>
                    <option key="4" value='Mr'>Mr</option>
                    <option key="5" value='Mrs'>Mrs</option>
                </SelectInput>
                <FormikErrorMessage name="title" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="firstName">
                    First Name
                </Label>
                <TextInput name="firstName" />
                <FormikErrorMessage name="firstName" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="lastName">
                    Last Name
                </Label>
                <TextInput name="lastName" />
                <FormikErrorMessage name="lastName" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">
                    Email
                </Label>
                <TextInput name="email" />
                <FormikErrorMessage name="email" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="position">
                    Position
                </Label>
                <TextInput name="position" />
                <FormikErrorMessage name="position" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="company">
                    Company
                </Label>
                <TextInput name="company" />
                <FormikErrorMessage name="company" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">
                    Phone
                </Label>
                <TextInput name="phone" />
                <FormikErrorMessage name="phone" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="mobile">
                    Mobile
                </Label>
                <TextInput name="mobile" />
                <FormikErrorMessage name="mobile" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="web">
                    Web
                </Label>
                <TextInput name="web" />
                <FormikErrorMessage name="web" />
              </FormGroup>
            </Form>
            <br />
            <br />
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default ContactTemplateForm;
