import LoginData from "../models/login-data";
import { registerJwt } from "./callbacks";
import { loginReducer } from "./reducers";
import makeGlobalState from "../../../common/lib/make-global-state";

const loginPageGlobal = makeGlobalState(
  {
    loginData: LoginData.create()
  },
  [registerJwt],
  [loginReducer]
);

export default loginPageGlobal;
