import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Label from '../../ui/Label';
import FormikTextInput from '../../ui/FormikTextInput';
import FormGroup from '../../ui/FormGroup';
import LargeBox from '../../ui/LargeBox';
import Button from '@material-ui/core/Button';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';
import { FormikRadio } from '../../ui/FormikRadio';
import { FormControlLabel } from '@material-ui/core';

const validationSchema = yup.object({
  optimizeCode: yup.string().required(),
});

const ABTestingConfig = ({settings, onSubmit}) => {

  return (
    <LargeBox heading="Google Optimize Config">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          optimizeCode: settings?.code || '',
          disabled: settings?.disabled || false,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>
                Optimize code
              </Label>
              <FormikTextInput name="optimizeCode" />
              <CustomErrorMessage name="optimizeCode" />
            </FormGroup>
            <FormGroup>
              <Label>
                Disabled
              </Label>
              <FormControlLabel control={<FormikRadio name="disabled" value={false} />} label="No" />
              <FormControlLabel control={<FormikRadio name="disabled" value={true} />} label="Yes" />
              <CustomErrorMessage name="disabled" />
            </FormGroup>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </LargeBox>
  );
};

export default ABTestingConfig;
