import apiClientRequest from '../../common/apiclient';

export const apiGetContactTemplates = ({ page, itemsPerPage, search }) =>
    apiClientRequest('/contact-templates', {
        params: { page, itemsPerPage, search },
    });

export const apiSaveContactTemplate = (data) =>
apiClientRequest(`/contact-templates`, {
    method: 'POST',
    data,
});
export const apiGetContactTemplateById = (id) => apiClientRequest(`/contact-templates/${id}`);
export const apiDeleteContactTemplateById = (id) => apiClientRequest(`/contact-templates/${id}`, { method: 'DELETE', });
