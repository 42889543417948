import React from 'react';
import DatabaseCleanupList from './DatabaseCleanupList';
import { NotificationManager } from 'react-notifications';
import {
  apiCleanupActivities, apiFixContactsActivitiesWithDifferentSpaces,
  apiGetCorruptedActivitiesUnrestorableWithContacts,
  apiGetCorruptedActivitiesUnrestorableWithoutContacts,
} from './api';
import useAsyncOperation from '../../common/hooks/use-async-operation';

const DatabaseCleanupListContainer = () => {
  const [activitiesWithContacts, setActivitiesWithContacts] = React.useState([]);
  const [activitiesWithoutContacts, setActivitiesWithoutContacts] = React.useState([]);
  const [getActivitiesWithContacts, result1] = useAsyncOperation(apiGetCorruptedActivitiesUnrestorableWithContacts);
  const [getActivitiesWithoutContacts, result2] = useAsyncOperation(apiGetCorruptedActivitiesUnrestorableWithoutContacts);
  const [cleanupActivities, result3] = useAsyncOperation(apiCleanupActivities);
  const [fixContacts, result4] = useAsyncOperation(apiFixContactsActivitiesWithDifferentSpaces);

  const fetchCorruptedActivities = React.useCallback(async () => {
    try {
      const [
        result1,
        result2,
      ] = await Promise.all([
        getActivitiesWithContacts(),
        getActivitiesWithoutContacts(),
      ])

      setActivitiesWithContacts(result1);
      setActivitiesWithoutContacts(result2);
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, []);


  const handleCleanupActivities= React.useCallback(async () => {
    try {
      await cleanupActivities();
      NotificationManager.success('cleanup successful');
      fetchCorruptedActivities();
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, []);

  const handleFixContactActivitiesWithDiffSpace = React.useCallback(async () => {
    try {
      await fixContacts();
      NotificationManager.success('fix launched successfully');
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, []);

  React.useEffect(() => {
    fetchCorruptedActivities();
  }, [fetchCorruptedActivities]);
  return (
    <React.Fragment>
      <DatabaseCleanupList
        isLoading1={result2.loading || result3.loading}
        isLoading2={result1.loading || result4.loading}
        activitiesWithContacts={activitiesWithContacts}
        activitiesWithoutContacts={activitiesWithoutContacts}
        onRefresh={fetchCorruptedActivities}
        onCleanupContacts={handleCleanupActivities}
        onFixContactActivitiesWithDiffSpace={handleFixContactActivitiesWithDiffSpace}
      />
    </React.Fragment>
  );
};

export default DatabaseCleanupListContainer;
