import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Column, Row } from './Flex';

const useStyles = makeStyles((theme) => ({
  root: ({ row, spacing }) => {
    if (row) {
      return {
        '& > *:not(:last-child)': {
          marginRight: theme.spacing(spacing),
        },
      };
    } else {
      return {
        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(spacing),
        },
      };
    }
  },
}));

const Stack = React.forwardRef(({ row = true, spacing = 0, className, ...props }, ref) => {
  const classes = useStyles({ row, spacing });
  const classNames = clsx(classes.root, className);

  if (row) {
    return <Row ref={ref} className={classNames} {...props} />
  } else {
    return <Column ref={ref} className={classNames} {...props} />
  }
});

Stack.Column = React.forwardRef((props, ref) => <Stack ref={ref} row={false} {...props} />);

Stack.Row = React.forwardRef((props, ref) => <Stack ref={ref} row={true} {...props} />);

export default Stack;
