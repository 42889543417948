import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import LogsListContainer from '../pages/logs/containers/LogsListContainer';

const LogsRoutes = () => (
  <AppAccessSwitch role="ADMIN">
      <Route path="/logs" component={LogsListContainer} />

  </AppAccessSwitch>
);

export default LogsRoutes;
