import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import FormDialog from '../../ui/FormDialog';
import FormikTextInput from '../../ui/FormikTextInput';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';

const validationSchema = yup.object({
  backupURL: yup.string().required(),
})

const BackupURLSetupDialog = ({ backupURL, onSave, onCancel }) => {

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        backupURL,
      }}
      onSubmit={onSave}
    >
      {({ handleSubmit }) => (
        <FormDialog
          open
          title="Backup url"
          closeDialog={onCancel}
          buttons={[
            { type: 'ok', text: 'Save', onClick: handleSubmit },
            { type: 'cancel', text: 'Cancel' },
          ]}
        >
          <FormGroup>
            <Label htmlFor="backupURL">Backup URL</Label>
            <FormikTextInput name="backupURL" />
            <CustomErrorMessage name="backupURL" />
          </FormGroup>
        </FormDialog>
      )}
    </Formik>
  )
};

export default BackupURLSetupDialog;
