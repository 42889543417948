import React from 'reactn';

const useDeleteByTextConfirmationHandlers = (text = 'delete') => {
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const handleConfirmation = React.useCallback((event) => {
    setIsConfirmed(event.target.value === text);
  }, []);

  return [isConfirmed, handleConfirmation];
};

export default useDeleteByTextConfirmationHandlers;
