import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import InvoicesList from '../components/InvoicesList';
import { apiGetSubscriberInvoices } from '../api';
import { useQueryMap } from '../../../common/hooks/use-query';

const InvoicesListContainer = () => {
    const params = useParams();
    const queryMap = useQueryMap();
    const { date } = queryMap;
    const [
        currentPage,
        currentItemsPerPage,
        goToPage
    ] = usePaginate();

    const [
        invoices,
        setInvoices
    ] = useState([]);

    const [
        total,
        setTotal
    ] = useState(0);

    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment(date.split('|')[0]).startOf('month').format('YYYY-MM-DD'), // DD/MM/YYYY
        endDate: moment(date.split('|')[1]).endOf('month').format('YYYY-MM-DD'),
    });

    useEffect(() => {
        const fetchData = async () => {
        try {
            const { invoices, total } = await apiGetSubscriberInvoices(params.subscriptionId, {
                page: currentPage,
                itemsPerPage: currentItemsPerPage,
                rangeDate,
            });

            setInvoices(invoices);
            setTotal(total);
        } catch (err) {
            NotificationManager.error('Failed to load users. Please refresh or try again later');
        }
        };

        fetchData();
    }, [currentPage, currentItemsPerPage, params.subscriberId, rangeDate.endDate]);

    const handleDateChange = React.useCallback((date) => {
        setRangeDate({ ...rangeDate, ...date });
    }, [rangeDate]);

    return (
        <InvoicesList
            invoices={invoices}
            rangeDate={rangeDate}
            total={total}
            currentItemsPerPage={currentItemsPerPage}
            currentPage={currentPage}
            goToPage={goToPage}
            rangeDate={rangeDate}
            handleDateChange={handleDateChange}
        />
    );
};

export default InvoicesListContainer;
