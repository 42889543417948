import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import SuperUserSubscribersInvoicesContainer from '../pages/SuperUserSubscribersInvoices/containers/SuperUserSubscribersInvoicesContainer';

const SuperUserSubscribersInvoicesRoutes = () => (
  <>
    <AppAccessSwitch role="SALESMAN">
      <Route path="/super-users/:id/subscribers">
        <SuperUserSubscribersInvoicesContainer />
      </Route>
      <Redirect to="/super-users" />
    </AppAccessSwitch>

  </>
);

export default SuperUserSubscribersInvoicesRoutes;
