import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import Checkbox from '@material-ui/core/Checkbox';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import CanSeeUsingRole from '../../../common/components/CanSeeUsingRole';
import TooltipLink from '../../../ui/TooltipLink';
import {
  useAddPropertyToQueryString,
  useAddPropertiesToQueryString,
} from '../../../common/hooks/use-query';
import getSubscriptionStatus from '../../../common/lib/get-subscription-status';
import styled from 'styled-components';
import Select from '../../../ui/Select';
import TableHeader from '../../../ui/TableHeader';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const subStatusColors = {
  'ACTIVE': 'green',
  'PROCESSING': 'blue',
  'WAITING_FOR_PAYMENT': 'blue',
};

const SubscriptionRow = styled.tr`
  color: ${({ subscriptionStatus }) => subStatusColors[subscriptionStatus] || 'red'};
`;

const SubscriptionStatus = styled.td`
  text-transform: lowercase;
`;

const ActionSelect = styled(Select)`
  margin: 0 10px;
`;

const SubscribersList = ({
  onSearchChange, subscribers, onRefresh, onExport, currentPage, currentItemsPerPage, goToPage, total,
  onClickResetActivities, resetActivitiesOnGoing, selectSubscribers, onAssignSubscriberClick, selectedPlan, plans = [], onPlanChange,
  history,
}) => {
  const handleSearchChange = React.useCallback(debounce(onSearchChange, 500), [onSearchChange]);
  const addPropertiesToQueryString = useAddPropertiesToQueryString();
  const addPropertyToQueryString = useAddPropertyToQueryString();

  const handlePlanChange = React.useCallback(evt => onPlanChange(evt.target.value), [onPlanChange]);

  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleCheckBoxChange = (event) => {
    if (!event.target.checked) {
      history.push(`?${addPropertiesToQueryString([
        ['fromDate', ''],
        ['toDate', '']
      ])}`);
    } else {
      history.push(`?${addPropertiesToQueryString([
        ['fromDate', fromDate ? moment(fromDate).format('YYYY-MM-DD') : moment().subtract(1, 'months').format('YYYY-MM-DD')],
        ['toDate', toDate ? moment(toDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')]
      ])}`);
    };
    setChecked(event.target.checked);
  }

  React.useEffect(() => {
    if (fromDate && toDate) {
      history.push(`?${addPropertiesToQueryString([
        ['fromDate', moment(fromDate).format('YYYY-MM-DD')],
        ['toDate', moment(toDate).format('YYYY-MM-DD')]
      ])}`);
    }
  }, [fromDate, toDate]);

  return (
    <LargeBox heading="Subscribers">
      <ContentNavBar>
        <NavBarActions>
          <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
          <TooltipButton icon="cloud_upload" text="export" onClick={onExport} />
          <SearchClearableInput placeholder="Search ..." searchProperty="search" onChange={handleSearchChange} />
          {
            plans.length > 0 && (
              <ActionSelect defaultValue={selectedPlan} onChange={handlePlanChange}>
                <option value="">Select a plan</option>
                {
                  plans.map(plan => <option key={plan._id} value={plan._id}>{plan.name}</option>)
                }
              </ActionSelect>
            )
          }
          <FormControlLabel
            control={<Checkbox checked={checked}
              onChange={handleCheckBoxChange}
            />}
            label="Sort by date range"
          />
          {checked && <Grid container justify="space-around">
            <Grid item xs={5}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                id="date-picker-inline"
                label="From"
                value={fromDate || moment().subtract(1, 'months').format('YYYY-MM-DD')}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                id="date-picker-inline"
                label="To"
                value={toDate || moment().format('YYYY-MM-DD')}
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={fromDate}
              />
            </Grid>
          </Grid>}
        </NavBarActions>
        <NavBarActions>
          <TooltipLink
            icon="add"
            text="New Subscriber"
            to={`?${addPropertyToQueryString(['action', 'create-subscriber'])}`}
          />
          <CanSeeUsingRole role="ADMIN">
            {
              resetActivitiesOnGoing ?
                <TooltipButton
                  icon="sync"
                  text="Wating..." color="#eb5a4699"
                /> :
                <TooltipButton
                  icon="sync"
                  onClick={() => onClickResetActivities()}
                  text="Reset search database"
                  color="#eb5a46"
                />
            }
          </CanSeeUsingRole>
        </NavBarActions>
      </ContentNavBar>
      <Table isEmpty={total === 0} isEmptyComponent="There are no subscribers. Please click on + to add one">
        <thead>
          <tr>
            <th>#</th>
            <TableHeader header={[
              { column: 'Full Name', orderBy: "firstName", textAlign: "left" },
              { column: 'Email', orderBy: "email", textAlign: "left" },
              { column: 'Salesman', orderBy: "salesman.firstName", textAlign: "left", role: "ADMIN" },
              { column: 'Plan', orderBy: "subscription.subscriptionPlan.name", textAlign: "left" },
              { column: 'Status', orderBy: "subscription.subscriptionPlan.createdAt", textAlign: "left" },
              { column: 'Subscribed from', orderBy: "subscribedFrom", textAlign: "left" },
              { column: 'Subscribed at', orderBy: "createdAt", textAlign: "center" },
            ]} history={history} />
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            subscribers.map(subscriber => (
              <SubscriptionRow subscriptionStatus={getSubscriptionStatus(subscriber.subscription)} key={subscriber._id} title={subscriber.subscribedFrom}>
                <td> <Checkbox value={subscriber._id} onChange={(e) => selectSubscribers(e)} color="default" /> </td>
                <td className="text-cell">{subscriber.lastName} {subscriber.firstName}</td>
                <td className="text-cell">{subscriber.email}</td>
                <CanSeeUsingRole role="ADMIN">
                  <td className="text-cell">
                    {
                      subscriber.salesman ? (
                        <span title={subscriber.salesman.email}>
                          {subscriber.salesman.firstName} {subscriber.salesman.lastName}
                        </span>
                      ) : 'Unassigned'
                    }
                  </td>
                </CanSeeUsingRole>
                <td className="text-cell">{subscriber?.subscription?.subscriptionPlan?.name || 'None'} </td>
                <SubscriptionStatus className="text-cell">{getSubscriptionStatus(subscriber?.subscription)} </SubscriptionStatus>
                <td className="text-cell" style={{ maxWidth: '100px', overflow: 'auto' }}>
                  {subscriber.subscribedFrom || '-'}
                </td>
                <td className="text-cell">{subscriber.createdAt ? moment(subscriber.createdAt).format('YYYY-MM-DD HH:mm') : '-'} </td>
                <TableActionCell>
                  <CanSeeUsingRole role="ADMIN">
                    <TooltipButton
                      icon="assignment"
                      text="Salesman"
                      onClick={() => onAssignSubscriberClick(subscriber._id)}
                    />
                  </CanSeeUsingRole>
                  <TooltipLink icon="people" text="details" to={`/subscribers/${subscriber._id}/users/`} />
                  <TooltipLink icon="list_alt" text="subscriptions" to={`/subscribers/${subscriber._id}/subscriptions/`} />
                  <TooltipLink
                    icon="edit"
                    text="edit"
                    to={`?${addPropertiesToQueryString([
                      ['action', 'edit-subscriber'],
                      ['id', subscriber._id]
                    ])}`} />
                  <TooltipLink
                    icon="delete"
                    text="delete subscriber"
                    to={`?${addPropertiesToQueryString([
                      ['action', 'delete-subscriber'],
                      ['id', subscriber._id]
                    ])}`} />
                </TableActionCell>
              </SubscriptionRow>
            ))
          }
        </tbody>
      </Table>
      <TableFooter>
        <Pagination
          currentPage={currentPage}
          currentItemsPerPage={currentItemsPerPage}
          onPageClick={goToPage}
          total={total}
        />
      </TableFooter>
    </LargeBox>
  );
};

export default SubscribersList;
