import React from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import moment from 'moment';
import MessageDialog from '../../../ui/MessageDialog';
import { useQueryMap } from '../../../common/hooks/use-query';
import TeamBoardTemplateForm from './TeamBoardTemplateForm';

const TeamBoardTemplatesList = ({ teamBoards, teamBoard, setTeamBoard, total, currentPage, currentItemsPerPage, goToPage, onRefresh, onSearchChange, onSave, OnEdit, onDelete, open, setOpen, loading, deleteDialogOpen, setDeleteDialogOpen}) => {
    const history = useHistory();

    const queryMap = useQueryMap();
    const { id } = queryMap;

    const handleSearchChange = React.useCallback(debounce(onSearchChange, 500), [onSearchChange]);

    const buttons = [{ text: 'Yes', type: 'ok', onClick: () => onDelete(id) },{ text: 'No', type: 'cancel' }];

    return (
        <LargeBox heading="Team board templates">
            <ContentNavBar>
                <NavBarActions>
                    <TooltipButton
                        icon="add"
                        onClick={() => { setOpen(true); }}
                        text="Add a new team board template"
                    />
                    <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
                </NavBarActions>
                <NavBarActions>
                    <SearchClearableInput placeholder="Search ..." searchProperty="search" onChange={handleSearchChange} />
                </NavBarActions>
            </ContentNavBar>
            <Table isEmpty={total === 0} isEmptyComponent="There are no team board templates.">
                <thead>
                    <tr>
                        <th className="text-cell">Title</th>
                        <th className="text-cell">Discription</th>
                        <th className="text-cell">Position</th>
                        <th className="text-cell">Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        teamBoards.map((teamBoard, key) => (
                            <tr key={key}>
                                <td className="text-cell">{teamBoard.title}</td>
                                <td className="text-cell" style={{ width: '50%'}}>{teamBoard.description}</td>
                                <td className="text-cell">{teamBoard.position}</td>
                                <td className="text-cell">{moment(teamBoard.createdAt).format('YYYY-DD-MM HH:mm')}</td>
                                <TableActionCell>
                                    <TooltipButton icon="edit" text="Edit" onClick={()=> OnEdit(teamBoard._id)}/>
                                    <TooltipButton icon="delete" text="remove" onClick={ () => {
                                        history.push(`?id=${teamBoard._id}`);
                                        setDeleteDialogOpen(true);
                                    }}/>
                                </TableActionCell>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <TableFooter>
                <Pagination
                    currentPage={currentPage}
                    currentItemsPerPage={currentItemsPerPage}
                    onPageClick={goToPage}
                    total={total}
                />
            </TableFooter>
            <MessageDialog
                open={deleteDialogOpen}
                title="Delete team board template"
                centertitle="true"
                closeDialog={()=> { setDeleteDialogOpen(false); history.push('team-board-card-templates'); }}
                buttons={buttons}
            >
                <p>You are about to delete this team board, this action is irreversible are you sure?</p>
            </MessageDialog>
            <TeamBoardTemplateForm
                title="Team Board Template"
                teamBoard={teamBoard}
                save={onSave}
                open={open}
                cancel={() => {setOpen(false); setTeamBoard({})}}
                loading={loading}
            />
        </LargeBox>
    );
};

export default TeamBoardTemplatesList;
