import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import { apiGetTeamBoardTemplates, apiSaveTeamBoardTemplate, apiGetTeamBoardById, apiDeleteTeamBoardById } from '../api';
import TeamBoardTemplatesList from '../components/TeamBoardTemplatesList';

const TeamBoardTemplatesListContainer = () => {

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    teamBoards,
    setTeamBoards
  ] = useState([]);

  const [
    teamBoard,
    setTeamBoard
  ] = useState({});


  const [
    total,
    setTotal
  ] = useState(0);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch } = queryMap;

  const fetchData = async () => {
    try {
      const { teamBoards, total } = await apiGetTeamBoardTemplates({
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        search: currentSearch,
      });

      setTeamBoards(teamBoards);
      setTotal(total);
    } catch (err) {
      NotificationManager.error('Failed to load team board templates. Please refresh or try again later');
    }
  };

  useEffect(() => {
    fetchData();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, history]);

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);


  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  const onSave = React.useCallback(async (form) => {
    try {
      setOpen(true);
      await apiSaveTeamBoardTemplate(form);
      fetchData();
      setOpen(false);
      setTeamBoard({});
    } catch (err) {
      NotificationManager.error('Failed to save team board template. Please refresh or try again later');
    }
    finally {
      setLoading(false)
    }
  }, []);

  const OnEdit = React.useCallback(async (id) => {
    try {
      setTeamBoard(await apiGetTeamBoardById(id));
      setOpen(true);
    } catch (err) {
      NotificationManager.error('Failed to load team board template. Please refresh or try again later');
    }
  }, []);

  const onDelete = React.useCallback(async (id) => {
    try {
      await apiDeleteTeamBoardById(id);
      fetchData();
      setDeleteDialogOpen(false);
      history.push('team-board-card-templates');
    } catch (err) {
      NotificationManager.error('Failed to delete team board template. Please refresh or try again later');
    }
  }, []);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Team Board Templates' }
        ]}
      />
      <TeamBoardTemplatesList
        teamBoards={teamBoards}
        teamBoard = {teamBoard}
        setTeamBoard={setTeamBoard}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        onRefresh={onRefresh}
        onSearchChange={onSearchChange}
        onSave={onSave}
        OnEdit={OnEdit}
        onDelete={onDelete}
        setOpen={setOpen}
        open={open}
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        loading={loading}
      />
    </>
  );
};

export default TeamBoardTemplatesListContainer;
