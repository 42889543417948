import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import UsersList from '../components/UsersList';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { apiGetSubscriberUsers } from '../../SubscriptionPlansList/api';

const UsersListContainer = () => {
  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    users,
    setUsers
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const [rangeDate, setRangeDate] = React.useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { users, total } = await apiGetSubscriberUsers(params.subscriberId, {
          page: currentPage,
          itemsPerPage: currentItemsPerPage,
          rangeDate,
        });

        setUsers(users);
        setTotal(total);
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetchData();
  }, [currentPage, currentItemsPerPage, params.subscriberId, rangeDate.endDate]);

  const handleDateChange = React.useCallback((date) => {
    setRangeDate({ ...rangeDate, ...date });
  }, [rangeDate]);

  return (
    <UsersList
      users={users}
      total={total}
      currentItemsPerPage={currentItemsPerPage}
      currentPage={currentPage}
      goToPage={goToPage}
      subscriberId={params.subscriberId}
      rangeDate={rangeDate}
      handleDateChange={handleDateChange}
    />
  );
};

export default UsersListContainer;
