import React from 'react';

const ReactTabContext = React.createContext({
  value: null,
});

export const TabPanel = ({ value, children }) => {
  const context = React.useContext(ReactTabContext);

  if (value === context.value) {
    return children;
  }

  return null;
}

export const TabContext = ({ value, ...props }) => (
  <ReactTabContext.Provider value={{ value }} {...props} />
);
