import apiClientRequest from '../../common/apiclient';

export const apiUpdateFeatureIntroConfig = ({ featureIntro, config, locale }) =>
  apiClientRequest('/features-intros-config', {
    method: 'PATCH',
    data: {
      locale,
      featureIntro,
      config,
    },
  });
