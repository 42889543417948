import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import TextInput from '../../../ui/formik/TextInput';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import SectionLoader from '../../../ui/SectionLoader';
import FormikRichText from '../../../ui/formik/FormikRichText';

const validationSchema = yup.object({
    title: yup.string().required(),
    discription: yup.string(),
    position: yup.number(),
});

const TeamBoardTemplateForm = ({ title, teamBoard, save, open, cancel, loading }) => {
  const history = useHistory();
  const submit = React.useCallback(submitOnce(save), [save]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id: teamBoard?._id || '',
        title: teamBoard?.title || '',
        description: teamBoard?.description || '',
        position: teamBoard?.position || 0,
      }}
      enableReinitialize
      onSubmit={submit}>
      {
        ({ handleSubmit }) => (
          <FormDialog
            open={open}
            title={title}
            closeDialog={cancel}
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' }
            ]}
            onExit={ () => history.push('team-board-card-templates') }
          >
            {
              loading && (
                <SectionLoader />
              )
            }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="title">
                    Title
                </Label>
                <TextInput name="title" />
                <FormikErrorMessage name="title" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">
                  description
                </Label>
                <FormikRichText name="description" />
                <FormikErrorMessage name="description" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="position">
                  Position
                </Label>
                <TextInput name="position" />
                <FormikErrorMessage name="position" />
              </FormGroup>
            </Form>
            <br />
            <br />
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default TeamBoardTemplateForm;
