import apiClientRequest from '../../common/apiclient';

export const apiGetActivitiesToRestoreFromSearch = () =>
  apiClientRequest('/corrupted-activities/search-activities-to-restore');

export const apiRestoreCorruptedActivitiesUsingSearch = () =>
  apiClientRequest('/corrupted-activities/search-restore', {
    method: 'POST',
  });

export const apiGetCorruptedActivitiesSearchRestoreResult = () =>
  apiClientRequest('/corrupted-activities/search-restoration-result');
