import React from 'react';
import { NotificationManager } from 'react-notifications';

export const INTERNAL_ERROR = 'INTERNAL_ERROR';

export const internalErrorReducer = function(global, dispatch, err) {
  console.error(err);
  NotificationManager.error('Something went wrong please try again later');
};

internalErrorReducer.action = INTERNAL_ERROR;
