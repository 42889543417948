import React from 'react';
import LargeBox from '../../ui/LargeBox';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormikTextInput from '../../ui/FormikTextInput';
import { Divider, FormControlLabel, FormGroup } from '@material-ui/core';
import Label from '../../ui/Label';
import Button from '@material-ui/core/Button';
import { Row } from '../../ui/Flex';
import { FormikRadio } from '../../ui/FormikRadio';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';

const validationSchema = yup.object({
  experimentId: yup.string().required('the experiment ID is is required'),
});

const ABTestingExperiments = ({ onSaveDemoSpaceExperimentId, experiments }) => {
  const experimentsMap = experiments.reduce((acc, val) => ({
    ...acc,
    [val.name]: val,
  }), {});

  return (
    <React.Fragment>
      <LargeBox>
        <Box>
          <Box my={2}>
            <Typography variant="h5">
              Testing demo space / test data
            </Typography>
          </Box>
          <Divider />
          <Box my={2}>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                experimentId: experimentsMap['DEMO_SPACE']?.experimentId || '',
                disabled: experimentsMap['DEMO_SPACE']?.disabled || false,
              }}
              onSubmit={onSaveDemoSpaceExperimentId}
            >
              {({handleSubmit}) => (
                <Form onSubmit={handleSubmit}>
                  <Box mb={2}>
                    <FormGroup>
                      <Label>Experiment ID</Label>
                      <FormikTextInput name="experimentId" />
                      <CustomErrorMessage name="experimentId" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Disabled</Label>
                      <Row>
                        <FormControlLabel control={<FormikRadio name="disabled" value={true} />} label="Yes" />
                        <FormControlLabel control={<FormikRadio name="disabled" value={false} />} label="No" />
                      </Row>
                    </FormGroup>
                  </Box>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </LargeBox>
    </React.Fragment>
  );
};

export default ABTestingExperiments;
