import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import TeamBoardTemplatesListContainer from '../pages/TeamBoardTemplates/containers/TeamBoardTemplatesListContainer';

const TeamBoardTemplatesRoutes = () => (
  <AppAccessSwitch role="SALESMAN">
      <Route path="/team-board-card-templates" component={TeamBoardTemplatesListContainer} />

  </AppAccessSwitch>
);

export default TeamBoardTemplatesRoutes;
