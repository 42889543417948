import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableFooter, TableActionCell } from '../../../ui/Table';
import Pagination from '../../../ui/Pagination';
import LargeBox from '../../../ui/LargeBox';
import printDate from '../../../common/lib/print-date';

const InvoicesList = ({ onRefresh, invoices, total, currentItemsPerPage, currentPage, goToPage, rangeDate, handleDateChange }) => {

  return (
    <LargeBox heading="Invoices">
        <ContentNavBar>
            <NavBarActions>
              <TooltipButton icon="refresh" onClick={onRefresh} />
            </NavBarActions>
            <NavBarActions>
                <KeyboardDatePicker style={{ marginRight: '10px'}}
                    autoOk={true}
                    disableToolbar
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="date-picker-start-date"
                    label="Start Date"
                    value={rangeDate.startDate}
                    onChange={(date)=>handleDateChange({ startDate: moment(date, 'YYYY-MM-DD').toDate()})}
                />

                <KeyboardDatePicker
                    autoOk={true}
                    disableToolbar
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="date-picker-end-date"
                    label="End Date"
                    value={rangeDate.endDate}
                    onChange={(date)=>handleDateChange({ endDate: moment(date, 'YYYY-MM-DD').toDate() })}
                />
            </NavBarActions>
          </ContentNavBar>
          <Table isEmpty={total === 0} isEmptyComponent="There are no invoices.">
            <thead>
              <tr>
                <th className="text-cell">Subscription Plan</th>
                <th className="date-cell">Period Start</th>
                <th className="date-cell">Period End</th>
                <th className="text-cell">Total</th>
                <th className="text-cell">Amount Paid</th>
                <th className="date-cell">status</th>
                <th className="date-cell">Billing Date</th>
                <th className="date-cell">Invoice PDF</th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map(invoice => (
                  <tr key={invoice._id}>
                    <td className="text-cell">{invoice.subscriptionPlan}</td>
                    <td className="date-cell">{printDate(invoice.periodStart)}</td>
                    <td className="date-cell">{printDate(invoice.periodEnd)}</td>
                    <td className="text-cell">€{invoice.total / 100}</td>
                    <td className="text-cell">€{invoice.amountPaid / 100}</td>
                    <td className="date-cell">{invoice.status}</td>
                    <td className="date-cell">{printDate(invoice.billingDate)}</td>
                    <TableActionCell>
                      <TooltipButton icon="cloud_download" text="Download Invoice"
                        onClick={() => window.open(invoice.invoicePDF)}
                      />
                    </TableActionCell>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <TableFooter>
            <Pagination
              currentPage={currentPage}
              currentItemsPerPage={currentItemsPerPage}
              onPageClick={goToPage}
              total={total}
            />
          </TableFooter>
    </LargeBox>
  )
};

export default InvoicesList;
