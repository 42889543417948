import apiClientRequest from '../../common/apiclient';

export const apiGetSubscriptionsPlans = ({ page, itemsPerPage}) => apiClientRequest('/subscription-plans-list', {
  params: {
    itemsPerPage,
    page
  }
});

export const apiGetSubscriberUsers = (subscriberId, { page, itemsPerPage, rangeDate }) =>
  apiClientRequest(`/users-list/${subscriberId}`, {
    params: {
      page,
      itemsPerPage,
      ...rangeDate,
    },
  });

export const apiGetSubscriptionsList = (subscriptionId, { page, itemsPerPage }) =>
  apiClientRequest(`/subscriptions-list/${subscriptionId}`, {
    params: {
      page,
      itemsPerPage,
    }
  });
