import React from 'react';
import FeaturesIntrosConfig from './FeaturesIntrosConfig';
import { NotificationManager } from 'react-notifications';
import { apiGetFeaturesIntrosConfig, apiUpdateFeaturesIntrosConfig } from './api';
import Stack from '../../ui/Stack';
import Breadcrumb from '../../ui/Breadcrumb';

const FeaturesIntrosConfigContainer = () => {
  const [featuresIntrosConfig, setFeaturesIntrosConfig] = React.useState({});
  const handleSubmit = React.useCallback(
    async ({ teamBoardEnabled, contactsEnabled, dashboardEnabled, dataImportExportEnabled, mailingEnabled, mailBoxEnabled, customizationEnabled }) => {
      try {
        await apiUpdateFeaturesIntrosConfig({
          teamBoardEnabled, contactsEnabled, dashboardEnabled,
          dataImportExportEnabled, mailingEnabled,
          mailBoxEnabled, customizationEnabled,
        });
        NotificationManager.success('Changes saved successfully');
      } catch (err) {
        NotificationManager.error('Something went wrong, please try again later');
      }
    }, []);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetFeaturesIntrosConfig();
        setFeaturesIntrosConfig(result);
      } catch (err) {
        NotificationManager.error('Something went wrong, please try again later');
      }
    };

    fetch();
  }, []);

  return (
    <Stack.Column spacing={2}>
      <Breadcrumb items={[
        { title: 'Features intro configuration' },
      ]} />
      <FeaturesIntrosConfig
        initialValues={featuresIntrosConfig}
        onSubmit={handleSubmit}
      />
    </Stack.Column>
  );
};

export default FeaturesIntrosConfigContainer;
