import React from 'react';
import { Formik, FieldArray } from 'formik';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import FormikTextInput from '../../ui/FormikTextInput';
import Button from '@material-ui/core/Button';
import Stack from '../../ui/Stack';
import FormikRichText from '../../ui/formik/FormikRichText';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { TabContext, TabPanel } from '../../shared/TabContext';

const validationSchema = yup.object({
  config: yup.array(yup.object({
    heading: yup.string().required('Heading is required'),
    content: yup.string().required('Content is required'),
    imageURL: yup.string().required('Please provide an image').url('Please provide a valid URL'),
  })),
});

const localeMap = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
};

const FeaturesIntroMakerForm = ({ config, onSave }) => {
  const [value, setValue] = React.useState('en');
  return (
    <TabContext value={value}>
      <Grid container>
        <Grid item xs={2}>
          <Box style={{borderRight: '1px solid #999'}}>
            <Tabs
              onChange={(e, newValue) => setValue(newValue)}
              orientation="vertical"
              variant="scrollable"
            >
              {['en', 'fr', 'es'].map(locale => (
                <Tab key={locale} value={locale} label={localeMap[locale]} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Stack.Column spacing={1} p={2}>
            <Typography variant="h6">
              {localeMap[value]}
            </Typography>
            {['en', 'fr', 'es'].map(locale => (
              <TabPanel key={locale} value={locale}>
                <Formik
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={onSave}
                  initialValues={{
                    locale,
                    config: config && config[locale] || [],
                  }}
                >
                  {({ values, handleSubmit, errors, touched }) => (
                    <Stack.Column spacing={2}>
                      <FieldArray
                        name="config"
                        render={helpers => {
                          return (
                            <Stack.Column spacing={2} alignItems="flex-start">
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => helpers.push({
                                  heading: '',
                                  content: '',
                                  imageURL: ''
                                })}>
                                New Row
                              </Button>
                              {values.config.length === 0 && (
                                <Box pt={2} alignSelf="stretch">
                                  <Typography style={{alignSelf: 'stretch'}} variant="body2" align="center">
                                    You don't have any rows
                                  </Typography>
                                </Box>
                              )}
                              {values.config.map((row, index) => (
                                <Stack.Column spacing={2} mb={2} key={index} width="100%" alignSelf="stretch">
                                  <Stack.Row spacing={1} justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">
                                      Row {index + 1}
                                    </Typography>
                                    <IconButton onClick={() => helpers.remove(index)}>
                                      <Close />
                                    </IconButton>
                                  </Stack.Row>
                                  <FormGroup>
                                    <Label>
                                      Heading
                                    </Label>
                                    <FormikTextInput name={`config.${index}.heading`} />
                                    <CustomErrorMessage name={`config.${index}.heading`} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>
                                      Content
                                    </Label>
                                    <FormikRichText name={`config.${index}.content`} />
                                    <CustomErrorMessage name={`config.${index}.content`} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>
                                      Image URL
                                    </Label>
                                    <FormikTextInput name={`config.${index}.imageURL`} />
                                    <CustomErrorMessage name={`config.${index}.imageURL`} />
                                  </FormGroup>
                                </Stack.Column>
                              ))}
                            </Stack.Column>
                          )
                        }}
                      />
                      {Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && (
                        <Alert severity="error">
                          Please complete the configuration
                        </Alert>
                      )}
                      <Button variant="contained" color="primary" size="small" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Stack.Column>
                  )}
                </Formik>
              </TabPanel>
            ))}
          </Stack.Column>
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default FeaturesIntroMakerForm;
