import React from 'react';
import moment from 'moment';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { NotificationManager } from 'react-notifications';
import { apiGetSuperUserSubscribers } from '../api';
import SuperUserSubscribersInvoices from '../components/SuperUserSubscribersInvoices';
import Breadcrumb from '../../../ui/Breadcrumb';
import { useQueryMap } from '../../../common/hooks/use-query';

const SuperUserSubscribersInvoicesContainer = () => {
  const queryMap = useQueryMap();
  const { email } = queryMap;
  const [
    currentPage,
    currentItemsPerPage,
    goToPage,
  ] = usePaginate();

  const [subscribers, setSubscribers] = React.useState({
    data: [],
    total: 0,
    loading: false,
  });

  const [rangeDate, setRangeDate] = React.useState({
    startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setSubscribers({ ...subscribers, loading: true });
        const { users, total } = await apiGetSuperUserSubscribers({
          email,
          page: currentPage,
          itemsPerPage: currentItemsPerPage,
          ...rangeDate,
        });

        setSubscribers({
          data: users,
          total: total,
          loading: false,
        });
      } catch (err) {
        setSubscribers({ ...subscribers, loading: false });
        NotificationManager.error('Failed to load subscribers. Please refresh or try again later');
      }
    };

    fetchData();

  }, [currentPage, currentItemsPerPage, email, rangeDate.endDate]);

  const handleDateChange = React.useCallback((date) => {
    setRangeDate({ ...rangeDate, ...date });
  }, [rangeDate]);

  return (<>
     <Breadcrumb
      items={[
        { title: 'Subscribers invoice' }
      ]}
    />
    <SuperUserSubscribersInvoices
        subscribers={subscribers.data}
        goToPage={goToPage}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        total={subscribers.total}
        loading={subscribers.loading}
        rangeDate={rangeDate}
        handleDateChange={handleDateChange}
    />
  </>)
};

export default SuperUserSubscribersInvoicesContainer;
