import React from 'react';
import FormikDateTimePicker from './FormikDateTimePicker';
import FormikTextInput from '../../../../ui/FormikTextInput';
import FormikSelect from '../../../../ui/FormikSelect';

const FormikCustomFieldInput = ({ name, content, type, validationRule }) => {

  if (type === 'text') {
    if (validationRule === 'DATE') {
      return <FormikDateTimePicker name={name} />;
    }

    if (validationRule === 'NUMBER') {
      return <FormikTextInput type="number" name={name} />;
    }

    return <FormikTextInput type="text" name={name} />;
  }

  return (
    <FormikSelect name={name}>
      <option value="">Please choose</option>
      {content.split(',')
        .map(value => (
          <option key={value} value={value}>{value}</option>
        ))}
    </FormikSelect>
  )
};

export default FormikCustomFieldInput;
