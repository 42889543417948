import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SubscribersListContainer from '../pages/SubscribersList/containers/SubscribersListContainer';
import UsersListContainer from '../pages/SubscriberUsersList/containers/UsersListContainer';
import SubscriptionsListContainer from '../pages/SubscriptionsList/containers/SubscriptionsListContainer';
import UpdateSubscriptionContainer from '../pages/EditSubscription/containers/UpdateSubscriptionContainer';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import EditSubscriberDialog from '../pages/EditSubscriber/EditSubscriber';
import DeleteSubscriberDialog from '../pages/DeleteSubscriber/DeleteSubscriber';
import QueryParamRoute from '../common/components/QueryParamRoute';
import SubscriberSalesmanAssignment from '../pages/SubscriberSalesmanAssignment/SubscriberSalesmanAssignment';
import CreateSubscriberDialogContainer from '../pages/AddSubscriber/CreateSubscriberDialogContainer';
import CanSeeUsingRole from '../common/components/CanSeeUsingRole';
import SubscriptionDetailsContainer from '../pages/SubscriptionDetails/SubscriptionDetailsContainer';
import InvoicesListContainer from '../pages/SubscriberUsersList/containers/InvoicesListContainer';

const SubscribersRoutes = () => (
  <>
    <AppAccessSwitch role="SALESMAN">
      <Route path="/subscribers/list">
        <SubscribersListContainer />
      </Route>
      <Route path="/subscribers/:subscriberId/users/subscriptions/:subscriptionId/invoices">
        <InvoicesListContainer />
      </Route>
      <Route path="/subscribers/:subscriberId/users">
        <UsersListContainer />
      </Route>
      <Route path="/subscribers/:subscriberId/subscriptions">
        <SubscriptionsListContainer />
      </Route>
      <Redirect from="/subscribers" to="/subscribers/list" />
    </AppAccessSwitch>
    <CanSeeUsingRole role="SALESMAN">
      <QueryParamRoute property="action" value="edit-subscriber" requiredParams={['id']}>
        <EditSubscriberDialog />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="delete-subscriber" requiredParams={['id']}>
        <DeleteSubscriberDialog />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="assign-subscriber" requiredParams={['subscriberId']}>
        <SubscriberSalesmanAssignment />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="create-subscriber">
        <CreateSubscriberDialogContainer />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="subscription-details" requiredParams={['subscriptionId']}>
        <SubscriptionDetailsContainer />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="edit-subscription" requiredParams={['subscriptionId']}>
        <UpdateSubscriptionContainer />
      </QueryParamRoute>
    </CanSeeUsingRole>
  </>
);

export default SubscribersRoutes;
