import React from 'react';
import AnnouncementDialog from './AnnouncementDialog';
import { NotificationManager } from 'react-notifications';
import { apiUpdateAnnouncement } from './api';
import useAsyncOperation from '../../common/hooks/use-async-operation';

const EditAnnouncementContainer = ({ announcement, onSave, onClose }) => {
  const [updateAnnouncement, process] = useAsyncOperation(apiUpdateAnnouncement);
  const handleSave = React.useCallback(async (values) => {
    try {
      const result = await updateAnnouncement(announcement._id, values);
      onSave(result);
      onClose();
    } catch (err) {
      console.error(err);
      NotificationManager.error('Something went wrong');
    }
  }, [announcement._id]);

  return (
    <AnnouncementDialog
      title="update announcement"
      initialValues={announcement}
      isLoading={process.loading}
      onSave={handleSave}
      onClose={onClose}
    />
  )
};

export default EditAnnouncementContainer;
