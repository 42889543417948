import React from 'react';
import DeletedContactsRestore from './DeletedContactsRestore';
import { useHistory } from 'react-router-dom';
import BackupURLSetupDialog from './BackupURLSetupDialog';
import { NotificationManager } from 'react-notifications';
import { apiGetCorruptedActivitiesRestorableFromBackup, apiRestoreCorruptedActivityFromBackup } from './api';
import useAsyncOperation from '../../common/hooks/use-async-operation';

const DeletedContactsRestoreContainer = () => {
  const history = useHistory();
  const [activities, setActivities] = React.useState([]);
  const [backupURL, setBackupURL] = React.useState('');
  const [getActivities, result1] = useAsyncOperation(apiGetCorruptedActivitiesRestorableFromBackup);
  const [restoreActivity, result2] = useAsyncOperation(apiRestoreCorruptedActivityFromBackup);

  const fetchCorruptedActivities = React.useCallback(async () => {
    try {
      const result = await getActivities({
        backupURL,
      });

      setActivities(result);
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, [backupURL]);

  const handleRestore = React.useCallback(async (id) => {
    try {
      await restoreActivity({
        id,
        backupURL,
      });

      setActivities(old => old.filter(c => c._id !== id));
      NotificationManager.success('restoration done');
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, [backupURL]);

  React.useEffect(() => {
    if (backupURL) {
      fetchCorruptedActivities();
    }
  }, [backupURL, fetchCorruptedActivities]);
  return (
    <React.Fragment>
      <DeletedContactsRestore
        isLoading={result1.loading || result2.loading}
        activities={activities}
        onRestore={handleRestore}
        onRefresh={fetchCorruptedActivities}
      />
      {!backupURL && (
        <BackupURLSetupDialog
          backupURL={backupURL}
          onCancel={() => history.goBack()}
          onSave={(data) => {
            setBackupURL(data.backupURL)
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DeletedContactsRestoreContainer;
