import React from 'react';
import AnnouncementDialog from './AnnouncementDialog';
import { NotificationManager } from 'react-notifications';
import { apiCreateAnnouncement } from './api';
import useAsyncOperation from '../../common/hooks/use-async-operation';

const CreateAnnouncementContainer = ({ onSave, onClose }) => {
  const [createAnnouncement, process] = useAsyncOperation(apiCreateAnnouncement);
  const handleSave = React.useCallback(async (values) => {
    try {
      const announcement = await createAnnouncement(values);
      onSave(announcement);
      onClose();
    } catch (err) {
      NotificationManager.error('Something went wrong');
    }
  }, []);

  return (
    <AnnouncementDialog
      title="Create new announcement"
      isLoading={process.loading}
      onSave={handleSave}
      onClose={onClose}
    />
  )
};

export default CreateAnnouncementContainer;
